import { Card, DataGrid } from "@canopyinc/aura";

import { maybeBuildAdjustmentRows } from "@/components/Transactions/utils";
import { usePluginColumns, usePlugins } from "@/hooks";
import { TransactionDetailsProps } from "..";

export const TransactionSummary = ({
  showBalance,
  showHint,
  timeZone,
  transaction,
}: TransactionDetailsProps & {
  showBalance?: boolean;
  showHint?: boolean;
}) => {
  const rootPlugins = usePlugins();
  const plugins = rootPlugins?.components?.transaction_details?.transaction_summary;
  const fields = {
    ...plugins?.fields,
    balance: {
      ...plugins?.fields?.balance,
      enabled: showBalance && plugins?.fields?.balance?.enabled,
    },
  };
  let columns = usePluginColumns(fields);

  if (!showBalance) {
    columns = columns.map((col) => {
      if (col.key === "original_amount") {
        return {
          ...col,
          displayName: "Amount",
          enabled: false,
        };
      }
      return col;
    });
  }

  const loading = transaction == null;

  return (
    <div className="flex flex-col gap-2">
      <Card>
        <DataGrid
          columns={columns}
          loading={loading}
          loadingRows={1}
          rows={
            loading
              ? []
              : [
                  ...maybeBuildAdjustmentRows({
                    showHint,
                    timeZone,
                    transaction,
                  }),
                ]
          }
          testid="transaction-summary"
        />
      </Card>
    </div>
  );
};
