import React from "react";

import cn from "classnames";

type LoaderProps = {
  classNames?: { wrapper?: string };
};

const Loader = ({ classNames }: LoaderProps) => (
  <div className={cn("w-full inline-flex justify-center items-center", classNames?.wrapper)}>
    <div
      style={{
        backgroundImage: `url("/assets/gif/canopy-loader-blue.gif")`,
        backgroundSize: "cover",
        width: 120,
        height: 120,
      }}
    />
  </div>
);

const FullScreenLoader = () => {
  return (
    <div className="absolute inset-0 bg-white" aria-live="polite" aria-busy="true">
      <Loader classNames={{ wrapper: "h-full" }} />
    </div>
  );
};

export { Loader, FullScreenLoader };
