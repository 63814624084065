import { TransactionHint } from "./TransactionHint";
import { TransactionStatus } from "./TransactionStatus";
import { TransactionFieldProps } from "../Transactions/TransactionTable";
import { ReversedPaymentIndicator } from "./ReversedPaymentIndicator";
import { TransactionTag } from "./TransactionTag";

type TransactionHighlightsProps = TransactionFieldProps & {
  showHint?: boolean;
};

export const TransactionHighlights = ({ transaction, showHint = true }: TransactionHighlightsProps) => {
  return (
    <div className="flex flex-row gap-1 justify-start" data-testid="transaction-highlights">
      <ReversedPaymentIndicator transaction={transaction} />
      <TransactionStatus transaction={transaction} />
      <TransactionTag transaction={transaction} />
      {showHint ? <TransactionHint transaction={transaction} /> : null}
    </div>
  );
};
