import React from "react";
import { motion } from "framer-motion";

export const Pulse: React.FC = ({ children }) => {
  return (
    <motion.div
      initial={{ scale: 1 }}
      animate={{ scale: 1.05 }}
      transition={{
        duration: 0.55,
        repeat: Infinity,
        repeatType: "mirror",
        type: "spring",
        stiffness: 150,
        damping: 20,
      }}
    >
      {children}
    </motion.div>
  );
};
