import React from "react";
import Link from "next/link";
import { Button, SnackbarMessage, useAuraMessages } from "@canopyinc/aura";
import { TOnboardStep } from "./onboarding-types";

export type TOnboardingNotifications = Record<TOnboardStep, TNoticeBody>;
export type TNoticeBody = {
  message: string;
  nextRoute?: string;
  buttonText?: string;
};

const NOTICES: TOnboardingNotifications = {
  ADD_APP_KEY: {
    message: "You successfully created an App Key.",
    buttonText: "Next: Add a Product",
    nextRoute: "/app/products",
  },
  ADD_PRODUCT: {
    message: "You successfully created a product.",
    buttonText: "Next: Add an Account",
    nextRoute: "/app/accounts",
  },
  ADD_ACCOUNT: {
    message: "You successfully created an account.",
    buttonText: "Next: Onboard a Customer",
    nextRoute: "/app/customers",
  },
  ADD_CUSTOMER: {
    message: "You successfully onboarded a customer and assigned them to an account.",
    buttonText: "Next: Add a Supplier",
    nextRoute: "/app/merchants",
  },
  ADD_MERCHANT: {
    message: "You successfully created a supplier.",
    buttonText: "Next: Create a Financing Request",
    nextRoute: "/app/finance-requests",
  },
  ADD_FINANCE_REQUEST: {
    message: "You successfully created a Financing Request.",
    buttonText: "Next: Invite a Team Member",
    nextRoute: "/app/team",
  },
  ADD_TEAM: {
    message: "Thanks for inviting a team member to join you on Canopy!",
  },
};

export const sendNotice = (addMessage: (message: SnackbarMessage) => void) => (key: TOnboardStep) => {
  if (!NOTICES[key]) return;
  send(addMessage)({
    message: NOTICES?.[key]?.message,
    buttonText: NOTICES?.[key]?.buttonText ?? undefined,
    nextRoute: NOTICES?.[key]?.nextRoute ?? undefined,
  });
};

export const send = (addMessage: (message: SnackbarMessage) => void) => (body: TNoticeBody) => {
  addMessage({
    duration: 8,
    // @ts-expect-error Aura snackbar typing only supports text, but it works at runtime
    content: (
      <div>
        {body.message}
        {body.nextRoute != null && body.buttonText != null && (
          <Link href={body.nextRoute} legacyBehavior>
            <a>
              <Button size="sm" variant="primary" className="ml-3">
                {body.buttonText}
              </Button>
            </a>
          </Link>
        )}
      </div>
    ),
  });
};
