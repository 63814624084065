import { useContext } from "react";
import PluginsContext from "@/context/plugins";
import { RecursivePartial } from "@/types";
import { CanopyOsPluginsSchema } from "@/context/plugins/types/generatedTypes";

/**
 * A custom hook to get current plugins config from PluginsContext
 * @returns the current plugins config object
 */
export const usePlugins = (): RecursivePartial<CanopyOsPluginsSchema> => {
  const plugins = useContext(PluginsContext);

  return plugins;
};
