import { Button, Card, DataGrid, DataGridColumn, Text, formatAmountCents, formatMMDDYY } from "@canopyinc/aura";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TransactionDetailsProps } from "..";
import { useI18n, usePluginColumns, usePlugins } from "@/hooks";
import { mdash } from "@/libs/string";

type BaseTransactionAdjustmentsProps = TransactionDetailsProps & {
  columns: DataGridColumn[];
  title?: string;
};

export const BaseTransactionAdjustments = ({
  adjustments,
  columns,
  loading,
  onSelectAdjustmentId,
  title,
}: BaseTransactionAdjustmentsProps) => {
  const rows =
    adjustments?.map((adjustment) => {
      return {
        ...adjustment,
        issued_at: formatMMDDYY(adjustment.issued_at),
        original_amount:
          adjustment?.original_amount_cents != null ? formatAmountCents(adjustment.original_amount_cents) : mdash,
        balance: adjustment?.balance_cents != null ? formatAmountCents(adjustment.balance_cents) : mdash,
        action: <Button iconOnly size="sm" variant="ghost" icon={<FontAwesomeIcon icon={faChevronRight} />} />,
      };
    }) ?? [];

  return (
    <div className="flex flex-col gap-2">
      {title != null ? <Text as="h3">{title}</Text> : null}
      <Card>
        <DataGrid
          columns={columns}
          loading={loading}
          onRowClick={(tx) => onSelectAdjustmentId?.(tx.transaction_id as string)}
          rows={rows}
          testid="transaction-adjustments"
        />
      </Card>
    </div>
  );
};

export const TransactionAdjustments = (props: TransactionDetailsProps) => {
  const rootPlugins = usePlugins();
  const plugins = rootPlugins?.components?.transaction_details?.transaction_adjustments;
  const columns = usePluginColumns(plugins?.fields);

  const { dictionary: rootDictionary } = useI18n();
  const dictionary = rootDictionary?.components?.transaction_details?.transaction_adjustments;
  const title = dictionary?.title ?? "Transaction Adjustments";

  return <BaseTransactionAdjustments columns={columns} title={title} {...props} />;
};
