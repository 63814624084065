import { TApi } from "@/api/useApi";
import Cookies from "js-cookie";

export type TUser = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  role: number;
  token: string;
  avatar?: string;
};

/**
 * Retrieves the JWT from cookie.
 */
export function getJWT(): string | null | undefined {
  const cookies = Cookies.get("Authorization");
  if (cookies) {
    return cookies;
  }

  if (typeof window !== "undefined") {
    return localStorage.getItem("Authorization");
  }
}

/** Removes the JWT from cookies */
export function removeJWT() {
  Cookies.remove("Authorization");
  if (typeof window !== "undefined") {
    localStorage.removeItem("Authorization");
  }
}

/** Sets the JWT into cookies.
    Cookie will expire in 1 day.
    SameSite policy is set to strict. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    Secure is set to true to require a secure protocol.
*/
export function setSession(jwt: string) {
  Cookies.set("Authorization", jwt, { expires: 1, sameSite: "strict", secure: process.env.NODE_ENV === "production" });
  if (typeof window !== "undefined") {
    localStorage.setItem("Authorization", jwt);
  }
}

/** Sets response interceptors that run on errors  */
export function setErrorInterceptors(api: TApi, onError?: (statusCode: number) => void) {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      switch (error?.response?.status) {
        case 401:
          onError?.(401);
          break;

        case 403:
          onError?.(403);
          break;

        case 404:
          onError?.(404);
          break;
      }

      return Promise.reject(error);
    }
  );
}

/**
 * Only returns a JWT as session.
 * Perhaps in the future it can return other values necessary to track sessions.
 */
export function getSession() {
  return {
    jwt: getJWT(),
  };
}
