import { useDownloadReport } from "@/api/reports/usePostReports";
import { Button, useAuraMessages, SnackbarMessage, Badge } from "@canopyinc/aura";
import { faArrowDownToLine } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DownloadableReports, ReportGranularity, ReportType } from "@/types/reports";
import { useDictionary } from "@/hooks/useI18n";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { track } from "@/libs/analytics";
import { PropsWithChildren } from "react";

type ReportDownloadButtonProps = {
  report: DownloadableReports;
  report_granularity: ReportGranularity;
};

type Indicators = {
  [K in ReportType]?: {
    seen: boolean;
  };
};

const ERROR_MESSAGE_DURATION_SECONDS = 4;

export const ReportDownloadButton = ({
  report,
  report_granularity,
  children,
}: PropsWithChildren<ReportDownloadButtonProps>) => {
  const { addMessage } = useAuraMessages();
  const dictionary = useDictionary();
  const [indicators, setIndicators] = useLocalStorage<Indicators>("report_indicators", {});

  const { mutate, loading } = useDownloadReport({
    onSuccess() {
      const updatedValue = {
        ...(indicators || {}),
        [report.report_type]: { seen: true },
      };
      setIndicators(updatedValue);
    },
    onError() {
      const errorMessage: SnackbarMessage = {
        content: dictionary.reports?.downloads?.default_error_message ?? "Report Download Failed.",
        color: "danger",
        duration: ERROR_MESSAGE_DURATION_SECONDS,
      };
      addMessage(errorMessage);
    },
  });

  function handleClick() {
    mutate(report);
    track("download_report", { report_type: report.report_type, report_granularity });
  }

  return (
    <span className="relative">
      <Button
        size="md"
        rightIcon={<FontAwesomeIcon icon={faArrowDownToLine} />}
        iconOnly={!children}
        loading={loading}
        disabled={loading}
        onClick={handleClick}
      >
        {children}
      </Button>
      {indicators?.[report.report_type]?.seen ? null : (
        <div className="absolute top-0 right-0">
          <Badge />
        </div>
      )}
    </span>
  );
};
