export const ACCOUNT_STATUSES = {
  ACTIVE: ["", "HARDSHIP/EXEMPTIONS"],
  SUSPENDED: ["BANKRUPTCY", "CHARGE_OFF", "DECEASED", "DELINQUENT", "FRAUD", "INACTIVITY", "INELIGIBLE", "RISK_REVIEW"],
  CLOSED: [
    "ADMINISTRATIVE",
    "BANKRUPTCY",
    "CHARGE_OFF",
    "CUSTOMER_REQUEST_PENDING_PAYOFF",
    "CUSTOMER_REQUEST",
    "DECEASED",
    "FRAUD",
    "GRANTOR_REQUEST_PENDING_PAYOFF",
    "GRANTOR_REQUEST",
    "INACTIVITY",
    "PAID_OFF",
    "RISK",
    "SETTLEMENT",
  ],
};
