import { add, format, startOfDay, endOfDay, isAfter, isBefore, isEqual, parse, getYear } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { toDate } from "date-fns-tz";

export const DATE_RANGES = {
  YESTERDAY: "YESTERDAY",
  TODAY: "TODAY",
  TOMORROW: "TOMORROW",
  NEXT_SEVEN_DAYS: "NEXT_SEVEN_DAYS",
};

export const formatForApi = (inputDate: Date | string) => {
  const date = new Date(inputDate);
  const formatted = `${format(date, "yyyy-MM-dd")}T${format(date, "HH:mm:ss")}.000`;
  return formatted;
};

// quick notes for anyone:
// after = the start of a filter
// before is the end of a filter
// dates -2 for the end of day would look to the end of day (1 second after midnight) from the day before yesterday

export const getDateRangeFilters = (values: string[]) => {
  const today = new Date();
  const yesterday = add(today, { days: -1 });
  const tomorrow = add(today, { days: 1 });
  const sevenDaysLater = add(today, { days: 7 });

  let after: Date[] = [];
  let before: Date[] = [];

  if (values.includes(DATE_RANGES.TOMORROW)) {
    after = [...after, startOfDay(tomorrow)];
    before = [...before, endOfDay(tomorrow)];
  }

  if (values.includes(DATE_RANGES.NEXT_SEVEN_DAYS)) {
    after = [...after, startOfDay(today)];
    before = [...before, endOfDay(sevenDaysLater)];
  }

  if (values.includes(DATE_RANGES.TODAY)) {
    after = [...after, startOfDay(today)];
    before = [...before, endOfDay(today)];
  }

  if (values.includes(DATE_RANGES.YESTERDAY)) {
    after = [...after, startOfDay(yesterday)];
    before = [...before, endOfDay(yesterday)];
  }

  const min = after.reduce((acc, curr) => {
    if (acc == null) {
      return curr;
    }
    return isAfter(acc, curr) ? curr : acc;
  });

  const max = before.reduce((acc, curr) => {
    if (acc == null) {
      return curr;
    }
    return isBefore(acc, curr) ? curr : acc;
  });

  return [formatForApi(min), formatForApi(max)];
};

export const getEpochOffsetMs = (input: string) => new Date(input).getTime();

export const parseWithTimeZone = (dateStr: string, formatStr: string, timeZone: string, referenceDate = new Date()) => {
  const zonedDate = utcToZonedTime(referenceDate, timeZone);
  const parsedDate = parse(dateStr, formatStr, zonedDate);
  return zonedTimeToUtc(parsedDate, timeZone);
};

export const isInfinity = (timestamp: string) =>
  timestamp?.toLowerCase() === "infinity" || Number(timestamp.split("-")[0] ?? 0) > 9999;

export type RecordWithTimestampRange = {
  effective_at_inclusive_start: string;
  effective_at_exclusive_end: string;
};

export const isRecordActiveAtTimestamp = <T extends RecordWithTimestampRange>(record: T, dateString: string) => {
  const inclusiveStart = toDate(record.effective_at_inclusive_start);
  const exclusiveEnd = isInfinity(record.effective_at_exclusive_end)
    ? add(new Date(), { years: 1000 }) // arbitrary date in a future age
    : toDate(record.effective_at_exclusive_end);

  const timestamp = new Date(dateString);

  const isAtOrAfterStart = isEqual(timestamp, inclusiveStart) || isAfter(timestamp, inclusiveStart);
  const isBeforeEnd = isBefore(timestamp, exclusiveEnd);
  return isAtOrAfterStart && isBeforeEnd;
};

export type TEffectiveRecord<T> = T & {
  effective_at: string;
};

export const sortByEffectiveAt = {
  ascending: <T extends TEffectiveRecord<K>, K>(a: T, b: T) =>
    isBefore(new Date(b.effective_at), new Date(a.effective_at)) ? 1 : -1,
  descending: <T extends TEffectiveRecord<K>, K>(a: T, b: T) =>
    isBefore(new Date(b.effective_at), new Date(a.effective_at)) ? -1 : 1,
};
