export const getKeys = <T>(items: T[], key: number | string) => {
  const keys: unknown[] = items.map((i) => i[key]).filter((val) => val != null);

  return Array.from(new Set(keys));
};

export const reduceByKey = <T extends {}>(items: T[], key: number | string) => {
  const keys = getKeys(items, key);

  return keys.map((val) =>
    items
      .filter((i) => i[key] === val)
      .reduce(
        (acc, curr) => ({
          ...acc,
          ...curr,
        }),
        {}
      )
  );
};
