import { TOnboardCompletion, TOnboardState } from "@/context/OnboardingContext/onboarding-types";
import { stepOrder } from "@/context/OnboardingContext";

/**
 * Restores state, lazy loaded from storage
 *
 * @remarks
 * This merges state from localStorage. if a request is
 * made that triggers the initial state to update before the
 * local record is loaded (which does happen) we'll want to merge
 * that with the initial state. This does that.
 *
 * This is much simpler than it looks, but in general, it should be
 * hands off, and there's couple improvements that need to be made
 * before that can happen.
 *
 * @param st: TOnboardingState - newly loaded persisted state
 * @param rs: TOnboardingState - the initial state, give or take an update
 * @returns The sum of both. It favors last
 *
 * @private not intended for use outside of OnboardingContext
 */
export function _mergeState(init: TOnboardState, store: TOnboardState): TOnboardState {
  const { completion: inc } = init;
  const { completion: stc } = store;
  let c: TOnboardCompletion = { ...inc };
  // determine which completion key to use (`true`) always wins
  for (let p in inc) {
    if (inc.hasOwnProperty(p)) {
      c[p] = stc[p] || inc[p];
    }
  }
  // determine the first step to be false (currentStep) using the newly merged completion state
  let next = stepOrder.find((k) => c[k] === false);
  // return the merged state in it's expected format
  return { completed: !next, activeStep: next, completion: c };
}
