import useSWR from "swr";
import { TApi, useApi } from "@/api/useApi";
import { TListResp } from "../api-types";
import { LineItem } from "@canopyinc/api-docs/types/ts/LineItem.type";
import { useRouter } from "next/router";
import { getCurrentPageNumber } from "@/libs/navigation";
import Decimal from "decimal.js-light";

export type TAccountLineItemsResp = TListResp<LineItem>;

/**
 * https://bump.sh/doc/canopy-in-progress-documentation#operation-get-accounts-parameter-line_items
 */
const getLineItemsByAccountId = async (api: TApi, url) => {
  return api.get<TAccountLineItemsResp>(url).then((res) => res.data);
};

enum LineItemQuery {
  limit = "limit",
  startingAfter = "starting_after",
  endingBefore = "ending_before",
  updatedAtAfter = "updated_at_after",
  updatedAtBefore = "updated_at_before",
  effectiveAtAfter = "effective_at_after",
  effectiveAtBefore = "effective_at_before",
  minOriginalAmountCents = "min_original_amount_cents",
  maxOriginalAmountCents = "max_original_amount_cents",
  lineItemStatus = "line_item_status",
  lineItemType = "line_item_type",
  search = "search_parameters",
}

export type LineItemQueryParams = keyof typeof LineItemQuery;

type LineItemQueryType = {
  [key in LineItemQueryParams]?: string | number;
};

const filterByList = Object.values(LineItemQuery).toString();

export const getFilteredSearchParams = (params: LineItemQueryType) => {
  const accumulator: string[] = [];
  return Object.keys(params)
    .filter((k) => filterByList.includes(k))
    .reduce((acc, key) => {
      const value = params[key];
      if (value) {
        acc.push(`${key}=${value}`);
      }
      return acc;
    }, accumulator)
    .join("&");
};

/**
 * Fetches line items for an account
 */
export function useLineItemsByAccountId(account_id: string, options: LineItemQueryType = {}) {
  const api = useApi();
  const includingDefaultParams = { limit: 1000, ...options };
  const queryParams = getFilteredSearchParams(includingDefaultParams);
  const router = useRouter();
  const currentPageNumber = getCurrentPageNumber(router.query) || 1;

  const { data, error, mutate } = useSWR(
    () => (account_id ? `/accounts/${account_id}/line_items?${queryParams}` : null),
    (url) => {
      return getLineItemsByAccountId(api, url);
    },
    { refreshInterval: 30_000 } // 30 seconds
  );

  return {
    transactions: data?.results ?? [],
    pagination: {
      currentPageNumber,
      currentPage: data?.paging,
    },
    error: error,
    loading: !error && !data,
    refetchTransactions: mutate,
  };
}
