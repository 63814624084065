import { Transaction } from "@canopyinc/api-docs/types/ts/Transaction.type";
import useSWR from "swr";

import { useApi } from "../useApi";

export type UseTransactionByIdOutput = {
  transaction?: Transaction;
  loading: boolean;
  error: boolean;
};

export const useTransactionById = (accountId?: string, transactionId?: string): UseTransactionByIdOutput => {
  const api = useApi();
  const { data: transaction, error } = useSWR(
    () => (accountId != null && transactionId != null ? `/accounts/${accountId}/transactions/${transactionId}` : null),
    (url) => (url != null ? api.get<Transaction>(url).then((res) => res.data) : null),
    { refreshInterval: 30_000 }
  );

  return {
    transaction: transaction ?? undefined,
    error,
    loading: transaction == null && !error,
  };
};
