// We don't have local databases b/c of Aurora/Lambda/Serverless,
// so when doing local development, we need to specify which env
// want to work against.
//
// WARNING: the DBs the local env's point to are the same as the
// deployed env's, so if you're working against the prod DB locally,
// you run the risk of messing up the prod database.
import { AxiosInstance, AxiosError } from "axios";
import { useApiContext } from "@/context/ApiContext";
import { resetAnalytics, track } from "@/libs/analytics";

export type TApiError = AxiosError;
export type TApi = AxiosInstance;

/** useApi will return the Canopy Api instance that should be making all network requests to our backends */
export function useApi(): AxiosInstance {
  const ctx = useApiContext();
  return ctx.instance;
}

/**
 * Returns a method to reset the api instance. Basically removes the JWT from memory.
 */
export function useAPIReset(): () => void {
  const ctx = useApiContext();
  return () => {
    ctx.clearSession(ctx.instance);
    track("Logged Out");
    resetAnalytics();
  };
}
