import { Button, Dropdown, DropdownItem, Text } from "@canopyinc/aura";

import { GetTransactionSplitsQuery } from "@/api/accounts/useTransactionSplits";
import { useI18n } from "@/hooks";

export type DropdownOption = {
  label: string;
  value?: string;
  filters: GetTransactionSplitsQuery;
};

export const ViewOtherSplitsButton = ({
  dropdownOptions,
  onSelect,
}: {
  onSelect: (option: DropdownOption) => void;
  dropdownOptions: DropdownOption[];
  timeZone?: string;
}) => {
  const { dictionary: rootDictionary } = useI18n();
  const dictionary = rootDictionary?.components?.transaction_details?.balance_splits?.view_other_splits;

  return (
    <Dropdown
      trigger={
        <Button size="sm" testid="view-other-splits">
          {dictionary?.button ?? "View Other Splits"}
        </Button>
      }
    >
      {/* TODO Handle If activeBefore < max of dates */}
      {dropdownOptions.map((option, i) => {
        const { label } = option;
        return (
          <DropdownItem
            key={label}
            onClick={() => onSelect(option)}
            classNames={{ item: "z-20" }}
            testid={`other-splits-option-${i}`}
          >
            <Text>{label}</Text>
          </DropdownItem>
        );
      })}
    </Dropdown>
  );
};
