import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Button, Text } from "@canopyinc/aura";
import { omit } from "lodash";

type TElementType = "link" | "button";

type TProps = {
  pageNumber: number;
  pageTitle?: string;
  selected?: boolean;
  disabled?: boolean;
  show?: boolean;
  cursor?: string;
  direction?: string;
  liClassNames?: string;
  type?: TElementType;
  noLink?: boolean;
  children?: React.ReactNode | (() => React.ReactNode);
  onClick?(): void;
};
/**
 * Cursor Pagination Navigation
 *
 * @note
 * This does not use ant.d because it will not work properly with cursor
 * pagination. This borrows their styles in order to maintain consistency
 * with our design library.
 */
export function PagerItem({
  pageNumber,
  pageTitle,
  cursor,
  direction,
  selected = false,
  disabled = false,
  show = true,
  liClassNames = "",
  type = "link",
  noLink,
  children,
  onClick = () => {},
}: TProps) {
  const router = useRouter();
  let { pathname, query } = router ?? {};
  // These URL Query Params drive the pagination state.
  // They will cause the app to refetch the API parameters based on the
  // provided cursor token. If pageNumber is "1", set query to undefined
  // which will force the router to reload the root level path.

  // we peel off pagination query params so we can set them ourselves, but keep other arbitrary params that may be needed to reconstruct the full URL
  const unrelatedQuery = omit(query, ["pg_cursor", "pg_dir", "pg_i"]);

  const newQuery =
    pageNumber > 1
      ? {
          ...unrelatedQuery,
          pg_cursor: cursor,
          pg_dir: direction,
          pg_i: pageNumber,
          // only add search string if it's defined
          ...(query?.s ? { s: query?.s } : {}),
        }
      : unrelatedQuery;

  // no show = no render
  if (show === false) return null;

  function el(type: TElementType) {
    switch (type) {
      case "link":
        // pager links 1, [2], 3, etc.
        return (
          <a rel="nofollow">
            <Text color="gray">{pageNumber}</Text>
          </a>
        );
      case "button":
        // next/prev buttons
        return <Button size="sm" disabled={disabled} onClick={onClick} iconOnly icon={<>{children}</>} />;
    }
  }

  return (
    <>
      {noLink || disabled ? (
        // if disabled
        el(type)
      ) : (
        // otherwise, wrap element in a router link
        <Link href={{ query: newQuery, pathname }} scroll={false}>
          {el(type)}
        </Link>
      )}
    </>
  );
}
