import useSWR from "swr";
import { TApi, useApi } from "@/api/useApi";
import { Account } from "./account";
import Decimal from "decimal.js-light";

/**
 * https://docs.canopyservicing.com/#operation-get-accounts-parameter
 */
export const getAccountByID = async (
  api: TApi,
  accountId?: string,
  opts: {
    external?: boolean;
  } = {
    external: false,
  }
) => {
  if (accountId == null) {
    return;
  }
  let url = `/accounts/${accountId}`;
  if (opts.external) {
    url = `/accounts/${accountId}?external=${opts.external}`;
  }

  return api.get<Account>(url).then((res) => res.data);
};

/**
 * Fetches a detailed account entity by ID
 */
export const useAccountByID = (accountId?: string, { initialData }: { initialData?: Account } = {}) => {
  const api = useApi();
  const { data, error, mutate } = useSWR(
    // The array here is essentially React's dep array. Pass is in the second url to ensure cache hits are unique per account id.
    () => (accountId ? [accountId, `/accounts/${accountId}`] : null),
    (id) => getAccountByID(api, id),
    {
      fallbackData: initialData,
      refreshInterval: 30_000, // 30 seconds
    }
  );
  return {
    account: data,
    refetchAccount: mutate,
    error,
    loading: !data && !error,
  };
};
