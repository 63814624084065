import { Transaction } from "@canopyinc/api-docs/types/ts/Transaction.type";
import { Filter, FilterChange, FilterConfiguration } from "@canopyinc/aura";
import { omit } from "lodash";
import { useRouter } from "next/router";
import { useState } from "react";

import { useAccountTransactions } from "@/api/accounts/useAccountTransactions";
import { Pagination } from "@/components/Pagination/CursorPagination";
import { LINE_ITEM_TYPES, STANDARD_LINE_ITEM_TYPES } from "@/constants";
import { DrawerType } from "@/context/AccountDrawersContext/types";
import { CanopyOsI18NSchema } from "@/context/i18n/types/generatedTypes";
import { CanopyOsPluginsSchema } from "@/context/plugins/types";
import { useI18n, usePluginColumns, usePlugins } from "@/hooks";
import { RecursivePartial } from "@/types";
// TODO extract this to a shared location -- probably needs some rework too
import { transformActiveFiltersToQueryObject } from "@/views/accounts/AccountTransactionsView/AccountTransactionsView";
import { getTransactionTableFilters } from "./filters";
import { TransactionTable } from "./TransactionTable";
import { ReportDownloadButton } from "../ReportDownloadButton";
import { ReportGranularity, ReportType } from "@/types/reports";

type TransactionsViewProps = {
  accountId: string;
  dictionary?: RecursivePartial<CanopyOsI18NSchema["components"]["transactions_view"]["list"]>;
  onClick?: (action: Partial<DrawerType>) => void;
  onSelect?: (transaction: Transaction, expectedDrawer: Partial<DrawerType>) => void;
  plugins?: RecursivePartial<CanopyOsPluginsSchema["components"]["transactions_view"]["list"]>;
  search?: string;
  showFilters?: boolean;
  showRecentOnly?: boolean;
  showDownload?: boolean;
  timeZone?: string;
};

type BaseTransactionsProps = {
  accountId: string;
  activeFilters?: FilterChange;
  onFilter?: (filters: FilterChange) => void;
  onSelect?: (transaction: Transaction, expectedDrawer: Partial<DrawerType>) => void;
  showFilters?: boolean;
  showRecentOnly?: boolean;
  showDownload?: boolean;
  timeZone?: string;
  transactions?: Omit<ReturnType<typeof useAccountTransactions>, "error" | "loading" | "refetchTransactions">;
};

export const BaseTransactions = ({
  accountId,
  activeFilters,
  onFilter = () => {},
  onSelect,
  showFilters,
  showRecentOnly,
  showDownload,
  transactions,
}: BaseTransactionsProps) => {
  const { dictionary: rootDictionary } = useI18n();
  const dictionary = rootDictionary.components?.transactions_view;

  const rootPlugins = usePlugins();
  const plugins = rootPlugins?.components?.transactions_view;
  const columns = usePluginColumns(plugins?.list?.fields);

  const filters = getTransactionTableFilters(dictionary?.list?.filters) ?? {};
  const filterList: FilterConfiguration[] = Object.values(filters).filter((f): f is FilterConfiguration => f != null);

  return (
    <div className="flex flex-col gap-4">
      {showFilters ? (
        <Filter
          classNames={{ wrapper: "relative z-10", panel: "z-10" }}
          activeFilters={activeFilters ?? {}}
          placeholder={dictionary?.list?.filters?.search?.placeholder}
          search
          filters={filterList}
          // @ts-expect-error terrible Aura typing
          setActiveFilters={onFilter}
          extra={
            <>
              {showDownload ? (
                <ReportDownloadButton
                  report={{ report_type: ReportType.transactions, report_parameters: { account_id: accountId } }}
                  report_granularity={ReportGranularity.account_level}
                />
              ) : null}
              {
                /* @ts-expect-error terrible Aura typing */
                <Pagination {...transactions?.pagination} loading={transactions?.loading ?? true} />
              }
            </>
          }
        />
      ) : null}
      <TransactionTable
        accountId={accountId}
        columns={columns}
        dictionary={dictionary?.list}
        onFilter={onFilter}
        onSelect={onSelect}
        plugins={plugins?.list}
        showRecentOnly={showRecentOnly}
        {...transactions}
      />
    </div>
  );
};

export const Transactions = ({
  accountId,
  showFilters = true,
  showRecentOnly = false,
  showDownload = false,
  search,
  timeZone,
  ...props
}: TransactionsViewProps) => {
  const [activeFilters, setActiveFilters] = useState<FilterChange>({
    search,
    filters: {},
  });

  const router = useRouter();
  const paginationAndSearchParams =
    router.query.pg_i && router.query.pg_dir && router.query.pg_cursor
      ? {
          ...(router.query.pg_dir === "next"
            ? { starting_after: router.query.pg_cursor }
            : { ending_before: router.query.pg_cursor }),
        }
      : {};
  const onFilter = (filters: FilterChange) => {
    router?.replace({ query: omit(router.query, ["text_search", "pg_i", "pg_cursor", "pg_dir"]) });
    setActiveFilters(filters);
  };
  const interestTypes: string[] = [LINE_ITEM_TYPES.AM_INTEREST, LINE_ITEM_TYPES.INTEREST];
  const accountTransactions = useAccountTransactions(accountId, {
    ...paginationAndSearchParams,
    ...(showRecentOnly
      ? {
          transaction_type: [
            ...Object.values(STANDARD_LINE_ITEM_TYPES).filter((type) => !interestTypes.includes(type)),
          ].join(),
        }
      : { limit: 20 }),
    ...transformActiveFiltersToQueryObject(activeFilters, timeZone, "text_search"),
  });

  return (
    <BaseTransactions
      {...props}
      accountId={accountId}
      onFilter={onFilter}
      showFilters={showFilters}
      showDownload={showDownload}
      transactions={accountTransactions}
    />
  );
};
