import { LocalStorageValues, localStorageKeys } from "@/constants/localStorageKeys";
import { useLocalStorage } from "./useLocalStorage";
import { Loan } from "@canopyinc/api-docs/types/ts/Loan.type";
import { useEffect } from "react";

export const useRestructureInProgress = (accountId: string, accountLoans: Loan[]) => {
  const [prevLoansFromRestructureCount, saveToStorage, removeFromStorage] = useLocalStorage<
    LocalStorageValues["restructuring_in_progress"]
  >(localStorageKeys.restructuring_in_progress(accountId));

  const loansFromRestructureCount = accountLoans.filter(
    (loan) => !!loan.restructured_from_line_item_ids?.length
  ).length;
  const isRestructuringInProgress = prevLoansFromRestructureCount != null;
  const isExpectedRestructuresCount = prevLoansFromRestructureCount == String(loansFromRestructureCount);

  const setRestructuringInProgress = () => {
    const expectedNewCount = loansFromRestructureCount + 1;
    saveToStorage(String(expectedNewCount));
  };

  useEffect(() => {
    if (isRestructuringInProgress && isExpectedRestructuresCount) {
      // the flag for figuring out if API is processing a new restructuring is no longer needed as the answer is NO
      removeFromStorage();
    }
  }, [isRestructuringInProgress, isExpectedRestructuresCount, removeFromStorage]);

  return { isRestructuringInProgress, setRestructuringInProgress };
};
