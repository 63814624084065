import React, { useEffect, useState } from "react";
import Link from "next/link";
import { useAPIReset } from "@/api/useApi";
import { useI18n } from "@/hooks/useI18n";
import { usePlugins } from "@/hooks/usePlugins";
import { Badge, Button, Dropdown, DropdownItem } from "@canopyinc/aura";
import { faChevronDown, faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdminDropDownItem, AdminDropDownItemProps, getItemKey } from "./AdminDropDownItem";
import { useCurrentUser } from "@/api/api-users/useCurrentUser";

export const AdminDropdown = ({ size }: { size?: "sm" | "md" | "lg" }) => {
  const plugins = usePlugins();
  const { dictionary: rootDictionary } = useI18n();
  const dictionary = rootDictionary?.admin_dropdown;

  const { user } = useCurrentUser();

  const resetApiAndLogout = useAPIReset();
  const handleLogout = () => {
    resetApiAndLogout();
  };

  const [isParentBadgeVisible, setIsParentBadgeVisible] = useState(false);

  // User is servicing or higher privilege
  const shouldShowAppKeys = user && user.role < 5 && plugins?.app_keys?.enabled;

  const dropdownItems: AdminDropDownItemProps[] = [
    { isEnabled: plugins?.team?.enabled, href: "/app/team", name: dictionary?.fields?.team || "Team" },
    {
      isEnabled: shouldShowAppKeys,
      href: "/app/keys",
      name: dictionary?.fields?.app_keys || "App Keys",
    },
    {
      isEnabled: plugins?.account_tag_options?.enabled,
      href: "/app/tag_options",
      name: dictionary?.fields?.account_tag_options || "Tag Options",
      isFeature: true,
    },
    {
      isEnabled: plugins?.settings?.enabled,
      href: "/app/settings",
      name: dictionary?.fields?.settings || "Settings",
    },
  ];

  useEffect(() => {
    const features = dropdownItems.filter((item) => item.isFeature);
    const anyFeatureNotSeen = features.some((feature) => {
      const featureSeen = localStorage.getItem(getItemKey(feature.name));

      return !featureSeen;
    });

    setIsParentBadgeVisible(anyFeatureNotSeen);
  }, [dropdownItems, setIsParentBadgeVisible]);

  const handleFeatureClick = (key: string) => {
    localStorage.setItem(key, "true");

    const features = dropdownItems.filter((item) => item.isFeature);
    const anyFeatureNotSeen = features.some((feature) => {
      const featureSeen = localStorage.getItem(getItemKey(feature.name));

      return !featureSeen;
    });

    setIsParentBadgeVisible(anyFeatureNotSeen);
  };

  return (
    <div>
      <Dropdown
        direction="up"
        classNames={{ wrapper: "w-full" }}
        trigger={
          <Button
            size={size}
            variant="ghost"
            icon={<FontAwesomeIcon icon={faUserCircle} />}
            rightIcon={
              <div className="grow flex justify-end">
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            }
            fullWidth
            classNames={{ button: "justify-start" }}
          >
            <div className="flex items-center gap-2">
              {dictionary?.title}
              {isParentBadgeVisible ? <Badge /> : null}
            </div>
          </Button>
        }
      >
        <Link passHref href="https://docs.canopyservicing.com/docs" legacyBehavior>
          <a target="_blank" rel="noopener noreferrer">
            <DropdownItem>{dictionary?.fields?.docs}</DropdownItem>
          </a>
        </Link>

        {dropdownItems.map((item, index) =>
          item.isEnabled ? <AdminDropDownItem key={index} {...item} onClick={handleFeatureClick} /> : null
        )}

        <div onClick={handleLogout}>
          <Link passHref={true} href="/signin">
            <DropdownItem>{dictionary?.fields?.logout}</DropdownItem>
          </Link>
        </div>
      </Dropdown>
    </div>
  );
};
