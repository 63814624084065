import * as Errors from "@/utils/error";

export const HTTP_ERRORS_BY_STATUS_CODE: {
  [statusCode in number]?: new (message?: string) => Error;
} = {
  400: Errors.BadRequestError,
  401: Errors.UnauthorizedError,
  403: Errors.ForbiddenError,
  404: Errors.NotFoundError,
  405: Errors.MethodNotAllowedError,
  409: Errors.ConflictError,
  500: Errors.InternalServerError,
  501: Errors.NotImplementedError,
  502: Errors.BadGatewayError,
  503: Errors.ServiceUnavailableError,
  504: Errors.GatewayTimeoutError,
};
