import { formatMMDDYY } from "@canopyinc/aura";
import type { PreviewDrawerDictionary } from "@/components/PreviewDrawer/PreviewAccountDetails";
import { startCase, toLower } from "lodash";
import { reduceByKey } from "@/libs/array";
import { AmortizationSchedule } from "@canopyinc/api-docs/types/ts/AmortizationSchedule.type";
import { AccountPreview } from "@/api/accounts/accountPreview";

export const getLastKey = (str: string) => {
  let matches = str.match(/\["(.*?)"\]/g);
  if (matches) {
    let lastMatch = matches[matches.length - 1];
    return lastMatch.match(/\["(.*?)"\]/)?.[1];
  } else {
    return str;
  }
};

export const formatIfDate = (value: string): string => {
  let date: Date;
  try {
    date = new Date(value);
  } catch (error) {
    return value;
  }
  if (isNaN(date.getTime())) {
    return value;
  }
  return formatMMDDYY(date);
};

export const getLabel = (key: string, dictionary?: PreviewDrawerDictionary) => {
  const rowLabels = dictionary?.account_details?.row_labels || {};
  return rowLabels[getLastKey(key) ?? "none"] || startCase(toLower(getLastKey(key)?.replace("_cents", "")));
};

export const getShowAmSchedules = ({
  previewData,
  amCurrent,
}: {
  previewData: AccountPreview | undefined | null;
  amCurrent?: AmortizationSchedule[];
}) => {
  const previewInstallments = reduceByKey(previewData?.complete_am_forecast ?? [], "line_item_id");
  const showPreviewAmSchedule = previewInstallments.length > 0;

  const currentInstallments = reduceByKey(amCurrent ?? [], "line_item_id");
  const showCurrentAmSchedule = currentInstallments.length > 0;

  return { showPreviewAmSchedule, showCurrentAmSchedule };
};
