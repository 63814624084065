import { Tag } from "@canopyinc/aura";
import { faArrowRightFromLine } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const RestructuredLoanTag = () => {
  return (
    <Tag color="gray" size="sm">
      <div className="whitespace-nowrap">
        <FontAwesomeIcon icon={faArrowRightFromLine} />
        <span className="ml-2">Restructured</span>
      </div>
    </Tag>
  );
};
