import { TPaging } from "@/api/api-types";
import { useRouter } from "next/router";

export type TPaginationSummary = {
  currentPageNumber: number;
  currentPage?: TPaging;
};

export type TPaginatedListResponse<T> = {
  data?: T[];
  pagination: TPaginationSummary;
  loading: boolean;
  error?: any;
  setSearchQuery: (val: string) => void;
};

/**
 ** Pagination helper functions
 */

export const LIMIT_DEFAULT = "25";

/** Helper to format the url-key for the next page */
function nextKey(rootPath: string, token: string, search = "", limit = LIMIT_DEFAULT) {
  return token ? `${rootPath}?limit=${limit}&starting_after=${token}${search}` : null;
}

/** Helper to format the url-key for the previous page */
function prevKey(rootPath: string, token: string, search = "", limit = LIMIT_DEFAULT) {
  return token ? `${rootPath}?limit=${limit}&ending_before=${token}${search}` : null;
}

/**
 * Determine whether to use root cursor key
 * or load the prev/next keys
 *
 * @param rootPath the root path of the paginated API request endpoint (i.e., "/customers/accounts")
 * @returns key for the current page request
 */
export function useRootKey(rootPath: string, search = "") {
  const router = useRouter();

  // Check for query params
  const query = router?.query || {};
  const cursor = query?.pg_cursor as string;
  const direction = query?.pg_dir as string;

  // if a direction param exists, use it
  // otherwise use root path
  switch (direction) {
    case "next":
      return `${nextKey(rootPath, cursor)}${search}`;
    case "prev":
      return `${prevKey(rootPath, cursor)}${search}`;
    default:
      return `${rootPath}?limit=${query.limit || LIMIT_DEFAULT}${search}`;
  }
}
