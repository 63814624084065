import React, { createContext, useEffect } from "react";
import { useRouter } from "next/router";

// state
import { useApi } from "@/api/useApi";
import { useCurrentUser } from "@/api/api-users/useCurrentUser";
import { initDatadog } from "@/libs/logger";
import { identifyUser } from "@/libs/analytics";

const AnalyticsContext = createContext(null);
export function AnalyticsProviderWrapper() {
  const axiosInstance = useApi();
  const hasAuthorizationHeader = Boolean(axiosInstance?.defaults?.headers?.["Authorization"]);

  // Do not render the provider if the AxiosInstance hasn't been initiated
  // properly with headers, or else there won't be an `Authorization` header
  // sent alongside the provider's request for user data. The request would
  // unauthenticate, causing the user to be redirected to the login view.
  return hasAuthorizationHeader ? <AnalyticsProvider /> : <></>;
}

const publicRoutes = ["/", "/signin", "/register", "/404", "/403", "/500"];

initDatadog();

// We may not need this provider since it only provides `null`. Through useCurrentUser
// it takes advantage of SWR to associate user metadata with the analytics data, but because SWR
// is implemented with hooks it can only be used within the React lifecycle.
//
// An alternative is to write and load a module, before the React App is mounted, that
// requests user data and initiates analytics independently
function AnalyticsProvider({ children }: { children?: React.ReactElement }) {
  const router = useRouter();

  // TODO: Remove the use of public routes. Should identify if user is authenticated or not.
  const { user } = useCurrentUser({ skip: publicRoutes.includes(router.pathname) });

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user) {
      identifyUser(user);
    }
  }, [user]);

  return <AnalyticsContext.Provider value={null}>{children}</AnalyticsContext.Provider>;
}

export default AnalyticsContext;
