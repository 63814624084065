import { subSeconds } from "date-fns";
import { formatInTimeZone, toDate } from "date-fns-tz";

interface TFormatOptions {
  exclusiveEnd?: boolean;
  timeZone?: string;
}

/**
 * Formats the date using the provided format string
 *
 * @arg date: an iso date `2018-07-01T09:10:14+00:00`
 * @arg format: date-fns-tz format tokens: https://github.com/marnusw/date-fns-tz/blob/master/README.md#formatintimezone
 */

export const formatDate =
  (dateFormat: string) =>
  (date: string | number | Date, options?: TFormatOptions): string => {
    const offsetSeconds = options?.exclusiveEnd ? 1 : 0;
    const timeZone = options?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    return formatInTimeZone(subSeconds(toDate(date), offsetSeconds), timeZone, dateFormat);
  };

/**
 * Format a date as MM/DD/YYYY
 *
 * @param date: an iso date `2018-07-01T09:10:14+00:00`
 * @returns a Date formatted as 07/01/2018
 */
export const formatMMDDYYYY = formatDate("LL/dd/yyyy");

/**
 * Format a date as MM/DD/YYYY
 *
 * @param date: an iso date `2018-07-01T09:10:14+00:00`
 * @returns a Date formatted as 07/01/18
 */
export const formatMMDDYY = formatDate("LL/dd/yy");

/**
 * Format a date as M/D/YY
 *
 * @param date: an iso date `2018-07-01T09:10:14+00:00`
 * @returns a Date formatted as 7/1/18
 */
export const formatMDYY = formatDate("M/d/yy");

/**
 * Format a date as M/D/YY
 *
 * @param date: an iso date `2018-07-01T09:10:14+00:00`
 * @returns a Date formatted as 7/1/2018
 */
export const formatMDYYYY = formatDate("M/d/yyyy");

/**
 * Format a date as "July 1, 2018"
 *
 * @param date: an iso date `2018-07-01T09:10:14+00:00`
 * @returns a Date formatted as "July 1, 2018"
 */
export const formatMonthDayYear = formatDate("LLLL d, yyyy");

/**
 * Format a date as "July 2018"
 *
 * @param date: an iso date `2018-07-01T09:10:14+00:00`
 * @returns a Date formatted as "July 2018"
 */
export const formatMonthYear = formatDate("LLLL yyyy");
