import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { getEnvConfig } from "@/config/environments";

// https://docs.datadoghq.com/real_user_monitoring/browser/data_collected/?tab=view
//
// GENERAL USE:
// 1. Just console.error() to log something to Datadog.
// 2. Throw an error and have it automatically forwarded to Datadog. Please do catch and handle the error too.
export function initDatadog() {
  const appEnv = getEnvConfig().appEnv;
  if (appEnv === "DEV") {
    return;
  }

  datadogRum.init({
    applicationId: "c187fa92-2750-405a-97ac-861aadde2bf9",
    clientToken: "pubd6c10bd01419d1c549ad2f3c58531cfe",
    service: "canopy-os",
    env: appEnv,
    version: "2021.01.11",
  });

  datadogLogs.init({
    clientToken: "pubd6c10bd01419d1c549ad2f3c58531cfe",
    service: "canopy-os",
    env: appEnv,
    version: "2021.01.11",
    forwardErrorsToLogs: true,
  });
}
