import Link from "next/link";
import { Badge, DropdownItem as AuraDropdownItem } from "@canopyinc/aura";
import { useLocalStorage } from "@/hooks/useLocalStorage";

export function getItemKey(name: string) {
  return `Feature-AdminDropdown-${name}`;
}

export interface AdminDropDownItemProps {
  isEnabled?: boolean;
  href: string;
  name: string;
  isFeature?: boolean;
  onClick?: (name: string) => void;
}

export const AdminDropDownItem = ({ isFeature = false, href, name, onClick }: AdminDropDownItemProps) => {
  const itemKey = getItemKey(name);
  const [featureSeen, setFeatureSeen] = useLocalStorage(itemKey, "false");

  const showBadge = isFeature && featureSeen === "false";

  const handleClick = () => {
    if (isFeature && onClick) {
      onClick(itemKey);
      setFeatureSeen("true");
    }
  };

  return (
    <Link key={href} passHref={true} href={href}>
      <AuraDropdownItem onClick={handleClick}>
        {name}
        {showBadge ? <Badge /> : null}
      </AuraDropdownItem>
    </Link>
  );
};
