export const APP_VERSION = "2.0.0";

interface EnvConfigProps {
  APIDomain?: string;
  assetsUrl?: string;
  auth0domain?: string;
  clientId?: string;
  gqlDomain?: string;
  cognitoClientId?: string;
  cognitoDomain?: string;
  userPoolId?: string;
  region?: string;
  authType?: string;
  ssoProvider?: string;
  telemetryEnabled: boolean;
  appEnv: string;
}

export function getEnvConfig() {
  return envConfig;
}

/**
 * For non-local environments, values are saved in Parameter Store
 */
export const envConfig: EnvConfigProps = {
  APIDomain: process.env.NEXT_PUBLIC_API_URL,
  assetsUrl: process.env.NEXT_PUBLIC_ASSETS_URL,
  auth0domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  clientId: process.env.NEXT_PUBLIC_CLIENT_ID,
  cognitoClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
  cognitoDomain: process.env.NEXT_PUBLIC_COGNITO_DOMAIN,
  userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  region: process.env.NEXT_PUBLIC_REGION,
  authType: process.env.NEXT_PUBLIC_AUTH_TYPE,
  ssoProvider: process.env.NEXT_PUBLIC_SSO_PROVIDER,
  telemetryEnabled: process.env.ENABLE_TELEMETRY === "true",
  appEnv: process.env.NEXT_PUBLIC_APP_ENV ?? "DEV",
};
