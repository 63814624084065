import { Card, DataGrid, DataGridColumn } from "@canopyinc/aura";
import { DrawerType } from "@/context/AccountDrawersContext/types";
import { formatMMDDYY } from "@/libs/date";
import { LineItem } from "@/types";
import { ReversedPaymentIndicator } from "./ReversedPaymentIndicator";
import { TransactionDescription } from "./TransactionDescription";
import { TransactionStatus } from "./TransactionStatus";
import { TransactionAmount } from "./TransactionAmount";
import { TransactionDropdown } from "./TransactionDropdown";
import { TransactionsCreditBalanceTag } from "@/components";
import chunk from "lodash/chunk";
import { useMemo, useState } from "react";
import { Pagination } from "@/components";
import { usePluginColumns } from "@/hooks/usePluginColumns";
import { usePlugins } from "@/hooks/usePlugins";
import { TransactionId } from "@/components/Transactions/TransactionId";

export type LineItemAction = Partial<DrawerType>;

export type TransactionsTableProps = {
  loading: boolean;
  onClick?: (action: LineItemAction) => void;
  onSelect?: (transaction: LineItem, expectedDrawer: LineItemAction) => void;
  selectable?: boolean;
  timeZone?: string;
  transactions: LineItem[];
  columns: DataGridColumn[];
};

export const TransactionsTable = ({
  loading,
  onClick,
  onSelect,
  selectable,
  transactions,
  timeZone,
  columns,
}: TransactionsTableProps) => {
  const loansFromRestructure = transactions
    .map((transaction) =>
      transaction.line_item_overview?.line_item_type === "LOAN"
        ? transaction.line_item_overview?.restructure_to_line_item_id
        : undefined
    )
    .filter(Boolean) as string[];

  const rows = (transactions ?? []).map((transaction) => {
    return {
      effective_at:
        transaction.effective_at != null ? formatMMDDYY(new Date(transaction.effective_at), { timeZone }) : null,
      line_item_id: <TransactionId id={transaction.line_item_id} />,
      description: (
        <div className="flex flex-row gap-3">
          <TransactionDescription lineItem={transaction} />
          <TransactionsCreditBalanceTag lineItem={transaction} />
          <TransactionStatus
            lineItem={transaction}
            isFromRestructure={loansFromRestructure.includes(transaction.line_item_id)}
          />
          <ReversedPaymentIndicator lineItem={transaction} />
        </div>
      ),
      original_amount: <TransactionAmount lineItem={transaction} />,
      action: selectable ? (
        <TransactionDropdown onClick={onClick} onSelect={onSelect} transaction={transaction} />
      ) : (
        " "
      ),
    };
  });

  return (
    <Card
      classNames={{
        card: "overflow-visible z-10",
        body: "bg-transparent",
      }}
    >
      <DataGrid
        loading={loading}
        columns={columns}
        onRowClick={() => {}}
        rows={rows}
        classNames={{
          card: "!rounded-2xl",
          table: "!rounded-2xl",
          tableWrapper: "!rounded-2xl !overflow-visible !md:overflow-visible",
          headerThead: "rounded-2xl",
        }}
        testid="transactions"
      />
    </Card>
  );
};

type TransactionsTableWithPagingProps = Omit<TransactionsTableProps, "columns"> & {
  pageSize?: number;
  pagination?: boolean;
};

const PAGE_SIZE = 10;

const TransactionsTableWithPaging = ({
  loading,
  onClick,
  transactions,
  timeZone,
  onSelect,
  selectable,
  pageSize = PAGE_SIZE,
  pagination = true,
}: TransactionsTableWithPagingProps) => {
  const rootPlugins = usePlugins();
  const plugins = rootPlugins?.accounts?.modules?.detail?.transaction_list;
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const txnChunks = useMemo(() => chunk(transactions, pageSize), [transactions]);
  const columns = usePluginColumns(plugins?.fields);

  const currentTransactionPage = txnChunks[Math.max(currentPageIndex, 0)] ?? [];

  return (
    <div>
      <TransactionsTable
        transactions={currentTransactionPage}
        loading={loading}
        onClick={onClick}
        onSelect={onSelect}
        selectable={selectable}
        timeZone={timeZone}
        columns={columns}
      />
      {pagination ? (
        <div className="flex justify-end mt-4">
          <Pagination currentPageIndex={currentPageIndex} pages={txnChunks} onPageChange={setCurrentPageIndex} />
        </div>
      ) : null}
    </div>
  );
};

export default TransactionsTableWithPaging;
