import { Text, Tag } from "@canopyinc/aura";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrystalBall } from "@fortawesome/pro-solid-svg-icons";

export type PreviewSubheadProps = { title?: string; label?: string };

const PreviewSubhead = ({ title = "", label }: PreviewSubheadProps) => {
  return (
    <div className="px-4 py-2 flex items-center justify-between border-gray-100 border-b-1 border border-t-0 border-x-0">
      <div className="flex items-center">
        <span
          className="inline-flex items-center justify-center w-8 h-8 mr-2 text-xl rounded-full bg-canopy-button-preview-bg-color text-canopy-button-preview-text-color"
          style={{
            background: "var(--canopy-colors-secondary-200)",
            color: "var(--canopy-colors-secondary-600)",
          }}
        >
          <FontAwesomeIcon icon={faCrystalBall} size="xs" />
        </span>
        <Text as="h3" className="inline-block font-bold text-sm" testid="preview-subhead-title">
          {title}
        </Text>
      </div>
      {label ? (
        <Tag color="extra" testid="preview-subhead-label">
          {label}
        </Tag>
      ) : null}
    </div>
  );
};

export default PreviewSubhead;
