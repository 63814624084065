import { TOnboardStep, TOnboardState, TOnboardAction } from "@/context/OnboardingContext/onboarding-types";
import { stepOrder } from "@/context/OnboardingContext";
import { saveState } from "@/libs/storage";
import { _mergeState } from "@/context/OnboardingContext/mergeState";
import { sendNotice } from "@/context/OnboardingContext/notifications";
import { ONBOARDING_STORE_KEY } from "@/context/OnboardingContext";
import { SnackbarMessage } from "@canopyinc/aura";

/**
 * Step Completion
 *
 * Triggers completion of a step, and tracks the
 * completion of the overall onboarding state
 *
 * @private not intended for use outside of OnboardingContext
 */
export function _completeStep(key: TOnboardStep, state: TOnboardState): TOnboardState {
  let { completion: c } = state;

  // mark this step complete
  c[key] = true;

  // find the first step that is incomplete
  // and that will be our next step
  let next = stepOrder.find((k) => c[k] === false);

  // Return an updated state
  return {
    ...state,

    // If activeStep is undefined,
    // onboarding is complete!
    completed: !next,

    // Set the active step to
    // whatever was set as next
    activeStep: next,

    // this will now contain our updated step
    completion: { ...c },
  };
}

/**
 * Reducer
 *
 * Determines the course of action
 *
 * @private not intended for use outside of OnboardingContext
 */
export const _reducer =
  (addMessage: (message: SnackbarMessage) => void) => (state: TOnboardState, action: TOnboardAction) => {
    // added safety, should validate that this never happens
    if (!state) return; // throw Error("Error w/ State Reducer!")

    switch (action.type) {
      // `TOnboardStep` handler ––
      // If a specific step requires additional logic,
      // this would be a safe place to perform that.
      case "ADD_APP_KEY":
      case "ADD_PRODUCT":
      case "ADD_ACCOUNT":
      case "ADD_CUSTOMER":
      case "ADD_TEAM":
        // if the key is invalid or it's already set to false, do nothing
        if (state.completion[action.type] !== false) {
          return state;
        }

        sendNotice(addMessage)(action.type);

        const nState = _completeStep(action.type, state);
        saveState(ONBOARDING_STORE_KEY, nState);
        return nState;

      // Local store handler ––
      // called w/ store record is resolved
      // this will merge the persisted state
      // w/ most recent initial state
      case "RESTORE":
        return _mergeState(state, action.restoredState);

      // this should never happen, but as an added
      // safety measure, return the state if no match
      default:
        return state;
    }
  };
