import { ACCOUNT_STATUSES } from "@/constants";
import { Option } from "@canopyinc/aura";
import { startCase } from "lodash";

export const statuses = Object.keys(ACCOUNT_STATUSES);
export const statusExists = (status: string): boolean => status != null && status !== "" && statuses.includes(status);

export const getSubStatuses = (status: string): string[] | null => {
  if (!statusExists(status)) {
    return null;
  }

  return ACCOUNT_STATUSES[status];
};

export const getSelectableStatus = (): Option[] => statuses.map((s) => ({ value: s, name: getFriendlyStatusName(s) }));
export const getSelectableSubStatus = (status: string): Option[] => {
  const subStatuses = getSubStatuses(status);
  return subStatuses != null ? subStatuses.map((s) => ({ value: s, name: getFriendlyStatusName(s) })) : [];
};

export const getFriendlyStatusName = (status: string) => startCase(status.toLowerCase());
export const getFriendlyStatusString = (status: string, subStatus: string, separator = "•") => {
  const friendlyStatus = getFriendlyStatusName(status);
  const friendlySubStatus = getFriendlyStatusName(subStatus);

  return friendlySubStatus === "" ? friendlyStatus : `${friendlyStatus} ${separator} ${friendlySubStatus}`;
};
