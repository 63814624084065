import React, { createContext, useEffect, useState } from "react";
import get from "lodash/get";
import merge from "lodash/merge";
import { getEnvConfig } from "@/config/environments";
import { CanopyOsI18NSchema } from "./types/generatedTypes";
import { RecursivePartial } from "@/types";

export type I18nDictionary = CanopyOsI18NSchema;

export type I18nTranslateFunction = (key: string) => string;
const defaultTranslate = () => "";

export type I18n = {
  i18n: I18nTranslateFunction;
  ready: boolean;
  dictionary: RecursivePartial<I18nDictionary>;
};

export const I18nContext = createContext<I18n>({
  i18n: defaultTranslate,
  ready: true,
  dictionary: {},
});

type I18nProviderProps = {
  assetsUrl: string;
  children: React.ReactNode;
};

export const I18nProvider = ({ assetsUrl, children }: I18nProviderProps) => {
  const [dictionary, setDictionary] = useState<RecursivePartial<CanopyOsI18NSchema>>({});

  useEffect(() => {
    const fetchI18n = async () => {
      const responses = await Promise.all([
        fetch(`${assetsUrl}/canopy-os/i18n_base.json`),
        fetch(`${assetsUrl}/canopy-os/i18n_custom.json`),
      ]);

      const [baseDictionary, customDictionary] = await Promise.all(
        responses.map(async (response) => {
          if (response.status === 200) {
            return await response.json();
          }
        })
      );

      setDictionary(merge(baseDictionary, customDictionary));
    };
    fetchI18n();
  }, []);

  const value: I18n = {
    /**
    * @deprecated Access the i18n dictionary directly with optional chaining instead
    * eventually the i18n function will be removed
      and this context will directly return the dictionary only
    */
    i18n: (path: string) => {
      const value = get(dictionary, path);
      if (
        getEnvConfig().appEnv === "DEV" &&
        Object.keys(dictionary).length > 0 &&
        (value == null || typeof value === "object")
      ) {
        throw new Error(`Attempted to translate missing or invalid i18n key in dictionary: ${path}`);
      }
      return value?.toString() ?? "";
    },
    ready: Object.keys(dictionary).length > 0,
    dictionary,
  };

  return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>;
};

export default I18nContext;
