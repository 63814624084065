import { Text } from "@canopyinc/aura";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import cn from "classnames";

import { LINE_ITEM_TYPES } from "@/constants";
import { TransactionFieldProps } from "./TransactionTable";

export const TransactionSummary = ({
  isAdjustmentByReversal,
  isEmphasized,
  transaction,
}: TransactionFieldProps & {
  isAdjustmentByReversal?: boolean;
  isEmphasized?: boolean;
}) => {
  const textClassName = cn({
    "bg-canopy-colors-success-light": isAdjustmentByReversal,
  });
  return (
    <div data-testid="transaction-summary" className="flex flex-row">
      {transaction.transaction_type === LINE_ITEM_TYPES.PAYMENT_REVERSAL ? (
        <div data-testid="transaction-summary-reversal-icon">
          <FontAwesomeIcon className="mr-2" icon={faClockRotateLeft} />
        </div>
      ) : null}
      <Text classNames={{ text: cn({ "font-bold": isEmphasized }) }}>
        <span className={textClassName}>{transaction.transaction_summary}</span>
      </Text>
    </div>
  );
};
