import { Transaction } from "@canopyinc/api-docs/types/ts/Transaction.type";
import { useRouter } from "next/router";
import useSWR from "swr";

import { getCurrentPageNumber } from "@/libs/navigation";
import { useApi } from "../useApi";
import { TListResp } from "../api-types";
import { toQueryParamString } from "@/utils/url";

type AccountTransactionsResponse = TListResp<Transaction>;

export type GetTransactionsQuery = {
  limit?: number;
  starting_after?: string;
  ending_before?: string;
  effective_at_after?: string;
  effective_at_before?: string;
  include_adjustment_summary?: boolean;
  is_adjustment?: boolean;
  is_reversed?: boolean;
  issued_at_after?: string;
  issued_at_before?: string;
  transaction_status?: string;
  transaction_type?: string;
  transaction_super_type?: string;
  max_balance_cents?: number;
  min_balance_cents?: number;
  max_original_amount_cents?: number;
  min_original_amount_cents?: number;
  sort_by?: string;
  text_search?: string;
};

export const useAccountTransactions = (account_id: string, options?: GetTransactionsQuery) => {
  const api = useApi();
  const includingDefaultParams = {
    limit: 10,
    include_adjustment_summary: true,
    is_adjustment: false,
    is_discarded: false,
    ...options,
  };
  const router = useRouter();
  const currentPageNumber = getCurrentPageNumber(router.query);

  const queryParams = toQueryParamString(includingDefaultParams);
  const { data, error, mutate } = useSWR(
    () => (account_id != null ? `/accounts/${account_id}/transactions?${queryParams}` : null),
    (url) =>
      url != null
        ? api.get<AccountTransactionsResponse>(url).then((res) => res.data)
        : { results: [], paging: { has_more: false } },
    { refreshInterval: 30_000 }
  );

  return {
    transactions: data?.results ?? [],
    pagination: {
      currentPageNumber,
      currentPage: data?.paging,
    },
    error,
    loading: !error && !data,
    refetchTransactions: mutate,
  };
};
