import { Tag } from "@canopyinc/aura";

import { LINE_ITEM_TYPES } from "@/constants";
import { LineItem } from "@/types";
import { PreviewLineItem } from "@/api/accounts/accountPreview";
import { RecursivePartial } from "@/types";
import { LineItemRelationship } from "@canopyinc/api-docs/types/ts/LineItem.type";

type IndicatorProps = {
  type?: string;
  description?: string;
  lineItemRelationships?: LineItemRelationship[];
};

export const Indicator = ({ type, lineItemRelationships }: IndicatorProps) => {
  const isReversedPayment =
    type === LINE_ITEM_TYPES.PAYMENT &&
    (lineItemRelationships?.some((relation) => relation.type === "PAYMENT_REVERSAL") ?? false);

  return (
    <>
      {isReversedPayment ? (
        <Tag
          testid="line-item-reversed"
          size="sm"
          color="default"
          classNames={{
            tag: "inline",
          }}
        >
          Reversed
        </Tag>
      ) : null}
    </>
  );
};

export const PreviewReversedPaymentIndicator = ({ lineItem }: { lineItem: RecursivePartial<PreviewLineItem> }) => {
  return <Indicator type={lineItem?.line_item_type} description={lineItem?.description} />;
};

export const ReversedPaymentIndicator = ({ lineItem }: { lineItem: LineItem }) => {
  return (
    <Indicator
      type={lineItem?.line_item_overview?.line_item_type}
      description={lineItem?.line_item_overview?.description}
      lineItemRelationships={lineItem?.line_item_relationships}
    />
  );
};
