import { Button, Text } from "@canopyinc/aura";
import { useState } from "react";

import { PaginationArrowIcon } from "./PaginationArrowIcon";

type StatefulPaginationProps = {
  loading: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  currentPageNumber: number;
  currentPage?: {
    starting_after?: string;
    ending_before?: string;
    has_more?: boolean;
  };
};

/**
 * This is a pagination prop which maintains its own pagination state, rather than using the router query params
 * This is required for nested pagination components, where the parent component may have its own pagination state that is held in the router
 */
export const StatefulPagination = ({
  currentPage,
  currentPageNumber,
  loading,
  onNext,
  onPrevious,
}: StatefulPaginationProps) => {
  const [direction, setDirection] = useState<"next" | "prev">("next");

  const enableNext = !loading && currentPage?.starting_after != null && (direction !== "next" || currentPage?.has_more);
  const enablePrevious =
    !loading &&
    currentPageNumber > 1 &&
    currentPage?.ending_before != null &&
    (direction !== "prev" || currentPage?.has_more);

  const hasPagination = loading || enableNext || enablePrevious;

  return hasPagination ? (
    <div className="flex flex-row gap-3" data-testid="pagination-bar">
      {/* Previous Page Button */}
      <Button
        size="sm"
        disabled={!enablePrevious}
        onClick={() => {
          setDirection("prev");
          onPrevious?.();
        }}
        iconOnly
        icon={<PaginationArrowIcon direction="prev" />}
        testid="previous"
      />
      {/* Current Page Number */}
      <Text testid="current-page">{currentPageNumber}</Text>
      {/* Next Page Button */}
      <Button
        size="sm"
        disabled={!enableNext}
        onClick={() => {
          setDirection("next");
          onNext?.();
        }}
        iconOnly
        icon={<PaginationArrowIcon direction="next" />}
        testid="next"
      />
    </div>
  ) : null;
};
