import { DataGridColumn } from "@canopyinc/aura";
import { useI18n } from "@/hooks/useI18n";

import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { FieldDefinition } from "@/context/plugins/types";

/**
 * A custom hook to get i18n-ized columns from plugins field definitions
 * @param fields An array of field definitions from plugins JSON
 * @returns An array of columns suitable for consumption by DataGrid aura component
 */
export const usePluginColumns = (rawFields: FieldDefinition["fields"] = []): DataGridColumn[] => {
  const { i18n } = useI18n();

  const fields = Array.isArray(rawFields)
    ? rawFields
    : Object.entries(rawFields).map(([key, value]) => ({ key, ...value }));

  return fields
    .filter((field) => field.enabled)
    .sort((a, b) => ((a.sort_key ?? 0) > (b.sort_key ?? 0) ? 1 : -1))
    .map((field, i) => ({
      displayName: field.i18n_key ? i18n(field.i18n_key) : "",
      key: field.key ?? `field_${i}`,
      ...(field.align && { align: field.align }),
      ...(field.width && { width: field.width }),
      ...(field.tooltip && {
        tooltip: {
          icon: faCircleInfo, // TODO make more flexible
          content: (
            <div dangerouslySetInnerHTML={{ __html: field?.tooltip?.i18n_key ? i18n(field.tooltip.i18n_key) : "" }} />
          ),
        },
      }),
    }));
};
