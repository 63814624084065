import React, { createContext, useState, useEffect, useContext } from "react";
import merge from "lodash/merge";
import { persistSettings } from "@/libs/storage";

export type TSettings = {
  direction?: string;
  responsiveFontSizes?: boolean;
  theme?: string;
  apiEnv?: string;
};

const defaultSettings = {
  direction: "ltr",
  responsiveFontSizes: true,
  apiEnv: "QA",
};

const SettingsContext = createContext<{
  settings: TSettings;
  saveSettings: (settings: TSettings) => void;
}>({
  saveSettings: () => {},
  settings: defaultSettings,
});

export function SettingsProvider({ children }: { children: React.ReactNode }) {
  const [currentSettings, setCurrentSettings] = useState<TSettings>(defaultSettings);

  const handleSaveSettings = (updatedSettings: TSettings = defaultSettings) => {
    const mergedSettings = merge({}, currentSettings, updatedSettings);
    setCurrentSettings(mergedSettings);
    persistSettings(mergedSettings);
  };

  useEffect(() => {
    document.dir = currentSettings.direction ?? "";
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export default SettingsContext;

export const SettingsConsumer = SettingsContext.Consumer;

export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("Must be used with SettingsContext");
  }

  return context;
};
