import { Tag, TagProps } from "@canopyinc/aura";

import { LINE_ITEM_STATUSES, LINE_ITEM_TYPES } from "@/constants";
import { capitalize, mdash } from "@/libs/string";
import { LineItem, LineItemStatus, LineItemType } from "@/types";
import { PreviewLineItem } from "@/api/accounts/accountPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { RecursivePartial } from "@/types";
import { TransactionFieldProps } from "../Transactions/TransactionTable";
import { RestructuredLoanTag } from "../LoanTags";

export type TransactionStatusProps = {
  lineItem?: LineItem;
};

export type PreviewStatusProps = {
  lineItem?: RecursivePartial<PreviewLineItem>;
};

export const LINE_ITEM_STATUS_COLORS: {
  [key in string]: TagProps["color"];
} = {
  [LINE_ITEM_STATUSES.AUTHORIZED]: "warning",
  [LINE_ITEM_STATUSES.DECLINED]: "danger",
  [LINE_ITEM_STATUSES.INVALID]: "danger",
  [LINE_ITEM_STATUSES.PENDING]: "warning",
  [LINE_ITEM_STATUSES.PROCESSING]: "default",
  [LINE_ITEM_STATUSES.REVERSED]: "default",
  [LINE_ITEM_STATUSES.VOID]: "danger",
  [LINE_ITEM_STATUSES.REFINANCED]: "default",
} as const;

// This is a map of line_item_types to line_item_statuses which persist only until next roll time
const TRANSIENT_STATUSES_BY_LINE_ITEM_TYPE: {
  [key in LineItemType]?: LineItemStatus[];
} = {
  [LINE_ITEM_TYPES.PAYMENT_REVERSAL]: [LINE_ITEM_STATUSES.PROCESSING],
};

export const hasTransientStatus = ({ type, status }: { type?: string | null; status?: string | null }) => {
  return (
    type != null &&
    status != null &&
    Object.keys(TRANSIENT_STATUSES_BY_LINE_ITEM_TYPE)?.includes(type) &&
    TRANSIENT_STATUSES_BY_LINE_ITEM_TYPE[type]?.includes(status)
  );
};

export const TransactionStatus = ({ transaction }: TransactionFieldProps) => {
  const type = transaction.transaction_type;
  const isRestructured = transaction.restructured_to_loan_id != null;

  const status = transaction.transaction_status;

  // edge case hack: if this is a pending -> reversed payment, don't show REVERSED status here
  // it will be displayed better by ReversedPaymentIndicator component which uses `reversed_by_transaction_id` field
  // to provide more context to the user
  if (status === LINE_ITEM_STATUSES.REVERSED && transaction.reversed_at != null) {
    return null;
  }

  return isRestructured || Object.keys(LINE_ITEM_STATUS_COLORS).includes(status) ? (
    <div className="flex flex-row gap-3">
      {isRestructured ? (
        <RestructuredLoanTag />
      ) : (
        <Tag
          testid="line-item-status"
          size="sm"
          color={LINE_ITEM_STATUS_COLORS[status] ?? "default"}
          classNames={{
            tag: "inline",
          }}
        >
          {capitalize(status)}
        </Tag>
      )}
      {hasTransientStatus({ type, status }) && (
        <div data-testid="line-item-status-spinner">
          <FontAwesomeIcon icon={faSpinnerThird} className="fa-spin" />
        </div>
      )}
    </div>
  ) : null;
};
