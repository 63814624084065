import { useI18n } from "@/hooks";
import { Tag, Tooltip } from "@canopyinc/aura";
import { faArrowLeftToLine } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const LoanFromRestructureTag = () => {
  const { dictionary } = useI18n();

  return (
    <Tooltip placement="top" content={dictionary?.accounts?.detail?.installments_card?.tags?.result_of_restructure}>
      <Tag color="sky" size="sm" classNames={{ tag: "h-6 mr-2" }}>
        <FontAwesomeIcon icon={faArrowLeftToLine} />
        <span className="ml-2">From Restructure</span>
      </Tag>
    </Tooltip>
  );
};
