import { datadogRum } from "@datadog/browser-rum";
import { getEnvConfig } from "@/config/environments";
import { TAPIUser } from "@/api/api-users/useCurrentUser";

/**
 * Custom properties defined in GA that allow us to segment analytics data by
 * user general user properties. These properties should only use low-cardinality
 * values.
 */
interface CustomUserSegmentProperties {
  api_role: number;
  canopy_environment: string;
  email_provider: string;
}

/**
 * Safely call analytics libs to track user
 *
 * If segment has not been initialized, window.analytics will be undefined.
 */
export function identifyUser(user: TAPIUser) {
  const envUserId = `${getEnvConfig().appEnv}-${user.api_user_id}`;

  const customUserSegmentProperties: CustomUserSegmentProperties = {
    api_role: user.role,
    canopy_environment: getEnvConfig().appEnv,
    email_provider: user.email.split("@")[1],
  };

  // Segment lib uses windows.analytics
  // @ts-ignore
  if (typeof window !== "undefined" && window.analytics) {
    // @ts-ignore
    window.analytics.identify(envUserId, {
      name: `${user.name_first} ${user.name_last}`,
      email: user.email,
      ...customUserSegmentProperties,
    });
  }

  datadogRum.setUser({
    id: envUserId,
    name: `${user.name_first} ${user.name_last}`,
    email: user.email,
  });
}

/**
 * Safely call analytics libs to track page
 *
 * If segment has not been initialized, window.analytics will be undefined.
 */
export function identifyPage(path: string) {
  // @ts-ignore
  if (typeof window !== "undefined" && window.analytics) {
    // @ts-ignore
    window.analytics.page(path);
  }
}

interface CustomEventAttributes {
  [key: string]: string | number;
}

/**
 * Safely call analytics libs to track an event
 *
 * If segment has not been initialized, window.analytics will be undefined.
 *
 * Naming convention for {eventName}
 * - Use lowercase since names can be treated as case-sensitive by downstream analytics
 * - Only use English letters, numbers, and underscores
 * - No whitespace
 * - Event names should start with a letter
 */
export function track(eventName: string, attributes: CustomEventAttributes = {}) {
  // @ts-ignore
  if (typeof window !== "undefined" && window.analytics) {
    // @ts-ignore
    window.analytics.track(eventName, attributes);
  }
}

/**
 * Safely call analytics libs to resets the session
 *
 * If segment has not been initialized, window.analytics will be undefined.
 */
export function resetAnalytics() {
  // @ts-ignore
  if (typeof window !== "undefined" && window.analytics) {
    // @ts-ignore
    window.analytics.reset();
  }
}
