import { titleCase, mdash } from "@/libs/string";
import { LINE_ITEM_TYPES } from "@/constants/lineItem";

export type formatTransactionDescrptionProps = {
  type?: string;
  allocation?: string | null;
  merchantName?: string | null;
};

export const formatTransactionDescription = ({ type, allocation, merchantName }: formatTransactionDescrptionProps) => {
  const isCharge = type?.includes(LINE_ITEM_TYPES.CHARGE);
  const isCreditOffset = type?.includes(LINE_ITEM_TYPES.CREDIT_OFFSET);
  const isOffset = type?.includes(LINE_ITEM_TYPES.CREDIT_OFFSET) || type?.includes(LINE_ITEM_TYPES.DEBIT_OFFSET);

  // allocation is baked into credit offset type, so strip that off
  const formattedType = isCreditOffset ? "Credit Offset" : titleCase(type ?? "");

  const formattedAllocation = allocation?.replace("AM_", "");

  const description = [formattedType, isCharge && merchantName, isOffset && formattedAllocation]
    .filter((part): part is string => Boolean(part)) // strip out missing parts
    .map(titleCase)
    .join(` ${mdash} `);

  return description || mdash;
};
