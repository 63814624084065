import { useCallback, useState } from "react";

export interface MultiStepsFlowService<T extends string, U = undefined> {
  currentStep: T;
  currentContext?: U;
  stepTo: (step: T, context?: U) => void;
}

export const useMultiStepsFlow = <T extends string, U = undefined>(
  initialStep: T,
  initialContext?: U
): MultiStepsFlowService<T, U> => {
  const [stepState, setStepState] = useState<{ step: T; context?: U }>({
    step: initialStep,
    context: initialContext,
  });

  const { step, context } = stepState;

  const stepTo = useCallback((newStep: T, newContext?: U) => {
    setStepState({ step: newStep, context: newContext });
  }, []);

  return {
    currentStep: step,
    currentContext: context,
    stepTo,
  };
};
