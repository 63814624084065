import storage from "localforage";
import { TSettings } from "@/context/SettingsContext";

export default storage;

/*
 * Save and Restore App Settings
 * TODO: Move this into generic state store functions
 */

export async function restoreSettings(): Promise<TSettings> {
  try {
    const settings: TSettings | null = await storage.getItem("settings");
    return settings ?? {};
  } catch (err) {
    console.warn("failed to settings from storage", err);
    return {};
  }
}

export function persistSettings(settings) {
  storage.setItem("settings", settings);
}

/**
 * Expose localforage API
 */
export const { getItem: restoreState, setItem: saveState } = storage;
