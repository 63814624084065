import { Input } from "@canopyinc/aura";
import { ControllerRenderProps } from "react-hook-form";
import BaseNumberFormat from "react-number-format";

type NumberFormatProps = BaseNumberFormat["props"] & { fieldRef: ControllerRenderProps["ref"] };

export const NumberFormat = ({ fieldRef, ...props }: NumberFormatProps) => (
  <BaseNumberFormat
    thousandSeparator
    fixedDecimalScale
    prefix="$"
    placeholder="0.00"
    decimalScale={2}
    customInput={Input}
    {...props}
    // dummy focus function that fixes interaction between react-hook-form & number format component
    ref={() => {
      fieldRef?.({
        focus: () => {},
      });
    }}
  />
);
