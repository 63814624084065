import { getEnvConfig } from "@/config/environments";
import { Auth } from "@aws-amplify/auth";

export const configureAuth = () => {
  const config = getEnvConfig();

  const authenticationFlowType = config.authType || "USER_SRP_AUTH";
  Auth.configure({
    Auth: {
      authenticationFlowType,
      region: config.region,
      userPoolId: config.userPoolId,
      userPoolWebClientId: config.cognitoClientId,
    },
  });
};
