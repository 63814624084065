import { ValueOf } from "@/types";

export function toFlatPropertyMap<T extends object>(obj: T) {
  const flattenRecursive = (obj: object, parentProperty?: string, propertyMap: Record<string, unknown> = {}) => {
    for (const [key, value] of Object.entries(obj)) {
      const property = parentProperty ? `${parentProperty}["${key}"]` : key;
      if (value && typeof value === "object") {
        flattenRecursive(value, property, propertyMap);
      } else {
        propertyMap[property] = value;
      }
    }
    return propertyMap;
  };
  return flattenRecursive(obj);
}

export function omitPropertiesBy<T extends object>(
  obj: T,
  predicate: ([key, val]: [string | number, ValueOf<T>]) => boolean
): Partial<T> {
  const validEntries = Object.entries(obj).filter(predicate);
  // Object.entries makes typing challenging, so this type assertion will have to do
  return Object.fromEntries(validEntries) as Partial<T>;
}

export function transformPropertiesBy<T extends object, K>(
  obj: T,
  transformer: (val: ValueOf<T>) => K,
  predicate: ([key, val]: [string | number, ValueOf<T>]) => boolean = () => true
) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, predicate([k, v]) ? transformer(v) : v]));
}
