import { Tag } from "@canopyinc/aura";

import { LineItem, LineItemOverview } from "@/types";
import { LineItem as StatementLineItem } from "@/api/accounts/statement";
import { PreviewLineItem } from "@/api/accounts/accountPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill } from "@fortawesome/pro-solid-svg-icons";
import { RecursivePartial } from "@/types";
import { useI18n } from "@/hooks/useI18n";
import { usePlugins } from "@/hooks/usePlugins";

// TODO: Remove these types entirely when the api docs are updated to include is_credit_balance
export type TransactionsCreditBalanceTagProps = {
  lineItem: LineItem & {
    line_item_overview?: LineItemOverview & {
      // TODO: this is currently a string in the api, and behind a feature flag. It may be a boolean in the future
      // It's also behind a flag so may not always be present for now. When this is more settled, we should
      // update it to something more specific like: is_credit_balance: boolean | null;
      // see https://github.com/canopy1/canopy-os/pull/1457#discussion_r1333055660
      is_credit_balance?: "true" | "false" | boolean | undefined;
    };
  };
};

export type StatementCreditBalanceTagProps = {
  lineItem: StatementLineItem & {
    line_item_overview?: LineItemOverview & {
      // TODO: this is currently a string in the api, and behind a feature flag. It may be a boolean in the future
      // It's also behind a flag so may not always be present for now. When this is more settled, we should
      // update it to something more specific like: is_credit_balance: boolean | null;
      // see https://github.com/canopy1/canopy-os/pull/1457#discussion_r1333055660
      is_credit_balance?: "true" | "false" | boolean | undefined;
    };
  };
};

export type PreviewCreditBalanceTagProps = {
  lineItem: RecursivePartial<PreviewLineItem>;
};

export const CreditBalanceBase = ({ label, variant }: { label?: string; variant: "short" | "long" }) => {
  return (
    <div className="flex flex-row gap-3 items-center">
      <Tag
        testid="line-item-credit-balance"
        size="sm"
        color="success"
        classNames={{
          tag: "inline-flex  gap-1 whitespace-nowrap",
        }}
      >
        <FontAwesomeIcon icon={faMoneyBill} className="text-emerald-600" />
        {variant === "long" ? label : null}
      </Tag>
    </div>
  );
};

export const PreviewCreditBalanceTag = ({ lineItem }: PreviewCreditBalanceTagProps) => {
  const { dictionary: rootDictionary } = useI18n();
  const label = rootDictionary?.accounts?.detail?.drawers?.statement_detail?.transaction_list?.credit_balance;
  const plugins = usePlugins();
  const enabled = plugins?.accounts?.credit_balance?.enabled;
  const isCreditBalance = String(lineItem?.is_credit_balance) === "true";

  return isCreditBalance && enabled ? <CreditBalanceBase label={label} variant="long" /> : null;
};

export const StatementCreditBalanceTag = ({ lineItem }: StatementCreditBalanceTagProps) => {
  const { dictionary: rootDictionary } = useI18n();
  const label = rootDictionary?.accounts?.detail?.transaction_list?.credit_balance;
  const plugins = usePlugins();
  const enabled = plugins?.accounts?.modules?.detail?.drawers?.statement_detail?.credit_balance?.enabled;
  const isCreditBalance = String(lineItem?.line_item_overview?.is_credit_balance) === "true";

  return isCreditBalance && enabled ? <CreditBalanceBase label={label} variant="long" /> : null;
};

export const TransactionsCreditBalanceTag = ({ lineItem }: TransactionsCreditBalanceTagProps) => {
  const { dictionary: rootDictionary } = useI18n();
  const label = rootDictionary?.accounts?.detail?.transaction_list?.credit_balance;
  const plugins = usePlugins();
  const enabled = plugins?.accounts?.credit_balance?.enabled;
  const isCreditBalance = String(lineItem?.line_item_overview?.is_credit_balance) === "true";

  return isCreditBalance && enabled ? <CreditBalanceBase label={label} variant="long" /> : null;
};
