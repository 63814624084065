import Decimal from "decimal.js-light";

export const stripWhitespace = (value: string) => value.replace(/\s/g, "");

export const isString = (value: unknown): value is string => typeof value === "string";
export const isNonEmptyString = (value: unknown): value is string => typeof value === "string" && value.length > 0;

export const NON_NUMERIC_CHARACTERS_REGEX = /[^0-9]/g;

export const stripNonNumericCharacters = (str: string) => `${str}`.replace(NON_NUMERIC_CHARACTERS_REGEX, "");

export const currencyStringToCents = (str: string) => {
  return new Decimal(stripNonNumericCharacters(str)).toInteger().toNumber();
};

export const isMMDDYY = (str: unknown) => {
  if (!isString(str)) {
    return false;
  }
  return /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{2}$/.test(str);
};

export const replaceSuffixes = (str: string, suffix: string, replacement: string) => {
  let clonedString = str;
  while (clonedString.endsWith(suffix)) {
    const index = clonedString.lastIndexOf(suffix);

    if (index >= 0) {
      clonedString = `${clonedString.substring(0, index)}${replacement}`;
    }
  }
  return clonedString;
};

export const replacePrefixes = (str: string, prefix: string, replacement: string) => {
  let clonedString = str;
  while (clonedString.startsWith(prefix)) {
    const index = clonedString.indexOf(prefix);

    if (index >= 0) {
      clonedString = `${replacement}${clonedString.substring(
        new Decimal(index).add(prefix.length).toNumber(),
        clonedString.length
      )}`;
    }
  }
  return clonedString;
};

export const stripConsecutiveWhitespaces = (str: string) => str.replace(/ +(?= )/g, "");

export const stripTabs = (str: string) => str.replace(/\t/g, "");
