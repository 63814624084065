import { DataGridColumn, Filter, FilterChange, Text } from "@canopyinc/aura";
import { parse } from "date-fns";
import Decimal from "decimal.js-light";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { omit } from "lodash";
import { isLoan } from "@/utils/loan";
import { useRouter } from "next/router";
import { useState } from "react";

import { useLineItemsByAccountId } from "@/api/accounts/useAccountLineItems";
import { useAccountByID } from "@/api/accounts/useAccountByID";
import { Pagination } from "@/components/Pagination/CursorPagination";
import { CanopyOsI18NSchema } from "@/context/i18n/types/generatedTypes";
import { useSelectedLineItem } from "@/context/SelectedLineItemContext";
import { useI18n, usePlugins, usePluginColumns, TPaginationSummary } from "@/hooks";
import useAccountDrawers from "@/hooks/useAccountDrawers";
import { LineItem, RecursivePartial } from "@/types";
import { TransactionsTable } from "@/views/accounts/AccountDetailView/TransactionsTable";

import { useAccountIDParam } from "../AccountDetailView/useAccountIDParam";
import { useSelectedTransaction } from "@/context/TransactionContext";

export type TransactionsBaseProps = {
  transactions: LineItem[];
  columns: DataGridColumn[];
  loading: boolean;
  handleOnSearch: React.Dispatch<React.SetStateAction<FilterChange>>;
  transactionsDictionary?: RecursivePartial<CanopyOsI18NSchema["accounts"]["detail"]["transactions"]>;
  pagination: TPaginationSummary;
  searchParameters: FilterChange;
};

const TransactionsBase: React.FC<TransactionsBaseProps> = ({
  transactionsDictionary,
  loading,
  transactions,
  columns,
  pagination,
  handleOnSearch,
  searchParameters,
}) => {
  const plugins = usePlugins();
  const { show: showDrawer } = useAccountDrawers();
  const { setLineItem, setSelectedLoanId } = useSelectedLineItem();
  const { setTransactionId } = useSelectedTransaction();

  const isLoansV2Enabled = plugins?.accounts?.modules?.detail?.loans?.enabled === true;

  return (
    <div className="p-8" data-testid="account_transactions">
      <div className="mb-4">
        <Text as="h3">{transactionsDictionary?.title}</Text>
      </div>
      <Filter
        activeFilters={searchParameters}
        placeholder={transactionsDictionary?.placeholder}
        classNames={{ wrapper: "relative z-10", panel: "z-10" }}
        search
        filters={[
          { name: "effective_at_after", displayName: "Starting Date", type: "date" },
          { name: "effective_at_before", displayName: "Ending Date", type: "date" },
          {
            name: "line_item_type",
            displayName: "Line Item Type",
            options: [
              {
                name: "Payments",
                value: "PAYMENT",
              },
              {
                name: "Charges",
                value: "CHARGE",
              },
              {
                name: "Fees",
                value: "MANUAL_FEE,RECURRING_FEE",
              },
              {
                name: "Credit Offset",
                value: "CREDIT_OFFSET,CREDIT_OFFSET_INTEREST,CREDIT_OFFSET_AM_INTEREST,CREDIT_OFFSET_FEE",
              },
              {
                name: "Debit Offset",
                value: "DEBIT_OFFSET",
              },
              {
                name: "Payment Reversal",
                value: "PAYMENT_REVERSAL",
              },
              {
                name: "Loan",
                value: "LOAN",
              },
              {
                name: "Origination Fee",
                value: "ORIG_FEE",
              },
            ],
          },
          {
            name: "line_item_status",
            displayName: "Line Item Status",
            type: "tiered_list",
            options: [
              {
                displayName: "Valid",
                options: [
                  { name: "Valid", value: "VALID" },
                  { name: "Authorized", value: "AUTHORIZED" },
                  { name: "Offset", value: "OFFSET" },
                  { name: "Posted", value: "POSTED" },
                  { name: "Retro Vaild", value: "RETRO_VALID" },
                  { name: "Reversed", value: "REVERSED" },
                  { name: "Rolled", value: "ROLLED" },
                  { name: "Settled", value: "SETTLED" },
                  { name: "Split Valid", value: "SPLIT_VALID" },
                ],
              },
              {
                displayName: "Invaild",
                options: [
                  { name: "Invalid", value: "INVAILD" },
                  { name: "Declined", value: "DECLINED" },
                  { name: "Split Invalid", value: "SPLIT_INVALID" },
                  { name: "Void", value: "VOID" },
                ],
              },
              {
                displayName: "Pending",
                options: [
                  { name: "Pending", value: "PENDING" },
                  { name: "Processing", value: "PROCESSING" },
                ],
              },
            ],
          },
          {
            name: "amount",
            displayName: "Amount",
            type: "range",
            min: { name: "min_original_amount_cents", displayName: "Greater than or equal" },
            max: { name: "max_original_amount_cents", displayName: "Less than or equal" },
          },
        ]}
        setActiveFilters={handleOnSearch}
        extra={<Pagination {...pagination} loading={loading} />}
      />
      <div className="mt-4 relative z-0" data-testid="account_tranactions_data_grid">
        <TransactionsTable
          loading={loading}
          transactions={transactions}
          columns={columns}
          onSelect={(li, expectedDrawer) => {
            if (isLoan(li) && isLoansV2Enabled) {
              setSelectedLoanId(li.line_item_id);
            } else {
              setLineItem(li);
              setTransactionId(li.line_item_id);
              showDrawer(expectedDrawer);
            }
          }}
          selectable
        />
      </div>
      <div className="flex justify-end mt-4">
        <Pagination {...pagination} loading={loading} />
      </div>
    </div>
  );
};

const shouldPerformSearch = (str = "") => str.length >= 3;

// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

export const DATETIME_KEYS = [
  "effective_at_after",
  "effective_at_before",
  "issued_at_after",
  "issued_at_before",
  "discarded_at_after",
  "discarded_at_before",
  "active_before",
];

export const transformActiveFiltersToQueryObject = (
  activeFilters: FilterChange,
  timeZone = "UTC",
  searchKey = "search_parameters"
) => {
  const { filters, search } = activeFilters;
  const filterQuery = Object.entries(filters ?? {}).reduce((acc, [key, value]) => {
    // join arrays into comma separated strings
    const parsedValue = Array.isArray(value) ? value.join(",") : value;

    if (parsedValue != null) {
      // Convert dollars to cents
      if (key.includes("_cents")) {
        return {
          ...acc,
          [key]: new Decimal(parsedValue).times(100).toInteger().toNumber(),
        };
      }

      // Convert dates to encoded ISO strings
      if (DATETIME_KEYS.includes(key)) {
        const zonedDate = utcToZonedTime(new Date(), timeZone);
        const parsedDate = parse(parsedValue, "MM/dd/yyyy", zonedDate);
        return {
          ...acc,
          [key]: zonedTimeToUtc(parsedDate, timeZone).toISOString(),
        };
      }

      return {
        ...acc,
        [key]: parsedValue,
      };
    }

    return acc;
  }, {});

  return {
    ...(shouldPerformSearch(search) ? { [searchKey]: search?.trim() } : {}),
    ...filterQuery,
  };
};

// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

export function AccountTransactionsView() {
  const accountId = useAccountIDParam();
  const { account } = useAccountByID(accountId);
  const router = useRouter();
  const { dictionary: rootDictionary } = useI18n();
  const rootPlugins = usePlugins();
  const plugins = rootPlugins?.accounts?.modules?.detail?.transaction_list;
  const columns = usePluginColumns(plugins?.fields);

  const searchStr = router.query.search_parameters?.toString() ?? "";
  const [activeFilters, setActiveFilters] = useState<FilterChange>({
    search: searchStr,
    filters: {},
  });
  const pointer =
    router.query.pg_i && router.query.pg_dir && router.query.pg_cursor && !shouldPerformSearch(searchStr)
      ? {
          ...(router.query.pg_dir === "next"
            ? { starting_after: router.query.pg_cursor }
            : { ending_before: router.query.pg_cursor }),
        }
      : {};

  const { transactions, pagination, loading } = useLineItemsByAccountId(accountId, {
    limit: 20,
    ...pointer,
    ...transformActiveFiltersToQueryObject(
      activeFilters,
      account?.account_product?.product_overview?.product_time_zone ?? "UTC"
    ),
  });
  const transactionsDictionary = rootDictionary.accounts?.detail?.transactions;

  const handleOnSearch = (filterChange: React.SetStateAction<FilterChange>) => {
    router?.replace({ query: omit(router.query, ["search_parameters", "pg_i", "pg_cursor", "pg_dir"]) });
    return setActiveFilters(filterChange);
  };

  return (
    <TransactionsBase
      transactions={transactions}
      columns={columns}
      loading={loading}
      transactionsDictionary={transactionsDictionary}
      handleOnSearch={handleOnSearch}
      pagination={pagination}
      searchParameters={activeFilters}
    />
  );
}

export default AccountTransactionsView;
