import React, { createContext, useState, useContext } from "react";

export type TNavBarContext = {
  collapsed: boolean;
  setCollapsed?: (value: boolean) => void;
};

const defaultSettings: TNavBarContext = {
  collapsed: false,
};

const NavBarContext = createContext<TNavBarContext>(defaultSettings);

export function NavBarProvider({ children }: { children: React.ReactNode }) {
  const [collapsed, setCollapsed] = useState<boolean>(defaultSettings.collapsed);

  return (
    <NavBarContext.Provider
      value={{
        collapsed,
        setCollapsed,
      }}
    >
      {children}
    </NavBarContext.Provider>
  );
}

export default NavBarContext;

export const useNavbar = () => {
  const context = useContext(NavBarContext);
  if (!context) {
    throw new Error("Must be used with NavbarContext");
  }

  return context;
};
