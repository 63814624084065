import { useCallback, useMemo, useState } from "react";
import chunk from "lodash/chunk";
import { Button } from "@canopyinc/aura";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";

type PaginationProps<T> = {
  currentPageIndex: number;
  onPageChange: (pageIndex: number) => void;
  pages: T[];
};

export const Pagination = <T,>({ currentPageIndex, onPageChange, pages }: PaginationProps<T>) => {
  const nextPage = useCallback(() => onPageChange(currentPageIndex + 1), [currentPageIndex, onPageChange]);
  const prevPage = useCallback(() => onPageChange(currentPageIndex - 1), [currentPageIndex, onPageChange]);

  return pages.length > 1 ? (
    <div className="flex flex-row gap-3">
      {/* previous button */}
      <Button
        disabled={currentPageIndex <= 0}
        icon={<FontAwesomeIcon icon={faChevronLeft} />}
        iconOnly
        onClick={prevPage}
        size="sm"
      >
        Previous Page
      </Button>
      {/* current page */}
      <>{currentPageIndex + 1}</>
      {/* next button */}
      <Button
        disabled={currentPageIndex >= pages.length - 1}
        iconOnly
        icon={<FontAwesomeIcon icon={faChevronRight} />}
        onClick={nextPage}
        size="sm"
      >
        Next Page
      </Button>
    </div>
  ) : (
    <></>
  );
};

export function usePagination<T>({ items = [], pageSize = 20 }: { items?: T[]; pageSize?: number } = {}) {
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page: number) => setCurrentPage(page);

  const itemChunks = useMemo(() => {
    return chunk(items, pageSize);
  }, [items, pageSize]);
  const itemsPerPage = itemChunks[Math.max(currentPage, 0)] ?? [];

  return { currentPage, handlePageChange, itemChunks, itemsPerPage };
}

export const chunksToPages = <T,>(chunks: T[][], onPaginate: (page: number) => void) => {
  return {
    ...Object.fromEntries(
      chunks?.map((_, index) => {
        return [index + 1, () => onPaginate(index + 1)];
      }) ?? []
    ),
  };
};
