import { Card, CardProps } from "@canopyinc/aura";
import PreviewSubhead, { PreviewSubheadProps } from "./PreviewSubhead";

type PreviewCardProps = PreviewSubheadProps & CardProps;

export function PreviewCard({ title, label, children, ...props }: PreviewCardProps) {
  return (
    <Card testid={"preview"} classNames={{ card: "mb-3" }} {...props}>
      <PreviewSubhead title={title} label={label} />
      {children}
    </Card>
  );
}

export default PreviewCard;
