import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

export const El = () => {
  // L shaped line indicating child datagrid row from design
  // This is not yet a first-class citizen in our design system as I doubt we will reuse it

  return (
    <div className="h-2 -mt-3" data-testid="el-adjustment-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="48" viewBox="0 0 12 48" fill="none">
        <line x1="12" y1="25.5" y2="25.5" stroke="#4A5973" />
        <line x1="0.5" y1="1" x2="0.499999" y2="26" stroke="#4A5973" />
      </svg>
    </div>
  );
};

export const Plus = () => (
  <div data-testid="plus-adjustment-icon" className="-ml-2">
    <FontAwesomeIcon icon={faPlus} />
  </div>
);

type AdjustmentIconType = "L";

export const AdjustmentCell = ({ children, iconType }: { children: ReactNode; iconType?: AdjustmentIconType }) => {
  if (iconType == null) {
    return <>{children}</>;
  }

  return (
    <div className="flex flex-row gap-2 pl-2 pt-0" data-testid="adjustment-cell">
      {iconType === "L" ? <El /> : null}
      {children}
    </div>
  );
};

export default AdjustmentCell;
