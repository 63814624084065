import { ListItem } from "@canopyinc/aura";

interface ListContentProps {
  title: string;
  value: React.ReactNode;
}

export const ListContent = ({ title, value }: ListContentProps) => (
  <ListItem>
    <div className="w-full flex justify-between">
      <div>{title}</div>
      <div className="font-bold">{value}</div>
    </div>
  </ListItem>
);
