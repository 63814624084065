export const handleApiError = (context: string, error: any) => {
  if (error) {
    const log = error?.status < 500 ? console.warn : console.error;
    log("Error calling API", context, error);
  }
};

export class HttpError extends Error {
  statusCode: number | undefined;

  constructor(message = "An HTTP error occurred", statusCode?: number) {
    super(message);
    this.name = this.constructor.name;
    this.statusCode = statusCode;
    Error.captureStackTrace(this, this.constructor);
  }
}

export class BadRequestError extends HttpError {
  constructor(message = "Bad Request") {
    super(message, 400);
  }
}

export class UnauthorizedError extends HttpError {
  constructor(message = "Unauthorized") {
    super(message, 401);
  }
}

export class ForbiddenError extends HttpError {
  constructor(message = "Forbidden") {
    super(message, 403);
  }
}

export class NotFoundError extends HttpError {
  constructor(message = "Not Found") {
    super(message, 404);
  }
}

export class MethodNotAllowedError extends HttpError {
  constructor(message = "Method Not Allowed") {
    super(message, 405);
  }
}

export class ConflictError extends HttpError {
  constructor(message = "Conflict") {
    super(message, 409);
  }
}

export class InternalServerError extends HttpError {
  constructor(message = "Internal Server Error") {
    super(message, 500);
  }
}

export class NotImplementedError extends HttpError {
  constructor(message = "Not Implemented") {
    super(message, 501);
  }
}

export class BadGatewayError extends HttpError {
  constructor(message = "Bad Gateway") {
    super(message, 502);
  }
}

export class ServiceUnavailableError extends HttpError {
  constructor(message = "Service Unavailable") {
    super(message, 503);
  }
}

export class GatewayTimeoutError extends HttpError {
  constructor(message = "Gateway Timeout") {
    super(message, 504);
  }
}

export const getErrorFromStatusCode = (statusCode: number) => {
  switch (statusCode) {
    case 400:
      return new BadRequestError();
    case 401:
      return new UnauthorizedError();
    case 403:
      return new ForbiddenError();
    case 404:
      return new NotFoundError();
    case 405:
      return new MethodNotAllowedError();
    case 409:
      return new ConflictError();
    case 500:
      return new InternalServerError();
    case 501:
      return new NotImplementedError();
    case 502:
      return new BadGatewayError();
    case 503:
      return new ServiceUnavailableError();
    case 504:
      return new GatewayTimeoutError();
    default:
      return new HttpError("An HTTP error occurred", statusCode);
  }
};
