import { Card, CardBody, ListItem, Text } from "@canopyinc/aura";

import { parseExternalFields } from "@/utils/externalFields";
import { TransactionDetailsProps } from ".";

export const ExternalFields = ({ transaction }: TransactionDetailsProps) => {
  const externalFieldCount = Array.isArray(transaction?.external_fields)
    ? transaction?.external_fields.length ?? 0
    : Object.keys(transaction?.external_fields ?? {}).length;

  const parsedExternalFields = parseExternalFields(transaction?.external_fields ?? {});
  {
    /* Show external fields if they exist */
  }
  return externalFieldCount > 0 ? (
    <div className="flex flex-col gap-4">
      <Text as="h3">External Fields</Text>
      <Card title="External Fields">
        <CardBody classNames={{ body: "p-0" }}>
          {parsedExternalFields.map((externalField) => {
            const [key] = Object.keys(externalField);
            const [value] = Object.values(externalField);

            return (
              <ListItem
                key={`external-field-${key}`}
                testid={`external-field-${key}`}
                classNames={{
                  li: "pt-2 pb-3",
                }}
              >
                <Text variant="strong" classNames={{ text: "pb-1" }}>
                  {key}
                </Text>

                <Text>{`${value}`}</Text>
              </ListItem>
            );
          })}
        </CardBody>
      </Card>
    </div>
  ) : null;
};
