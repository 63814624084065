import { Tag, TagProps, Text } from "@canopyinc/aura";
import { startCase, toLower } from "lodash";

export type SubStatusProps = {
  subStatus: string;
} & Omit<TagProps, "children">;

export const SubStatus = ({ subStatus, ...props }: SubStatusProps) => {
  if (typeof subStatus !== "string" || subStatus === "") return <Text>N/A</Text>;

  return (
    <Tag color="default" classNames={{ tag: "whitespace-nowrap" }} {...props} testid="sub-status">
      {startCase(toLower(subStatus)).replace("_", " ")}
    </Tag>
  );
};
