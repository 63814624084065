import { useEffect, useState } from "react";

// useLocalStorage custom hook to persist state in local storage
export const useLocalStorage = <T>(key: string, initial?: T): [T | undefined, (val: T) => void, () => void] => {
  const [val, setVal] = useState<T | undefined>(initial);

  useEffect(() => {
    const item = localStorage?.getItem(key);
    if (item !== undefined && item !== null) {
      try {
        const parsedData = JSON.parse(item) as T;
        setVal(parsedData);
      } catch (error) {
        console.error("Error parsing JSON:", { obj: item, error });
      }
    }
  }, []);

  const setLocalVal = (val: T) => {
    localStorage.setItem(key, JSON.stringify(val));
    setVal(val);
  };
  const removeLocalVal = () => {
    localStorage.removeItem(key);
    setVal(undefined);
  };

  return [val, setLocalVal, removeLocalVal];
};
