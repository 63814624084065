import { createContext, ReactNode, useCallback, useContext, useState } from "react";

import { LineItem } from "@/types";
import { Transaction } from "@canopyinc/api-docs/types/ts/Transaction.type";
import { useLineItem } from "@/api/accounts/useLineItem";
import { useRouter } from "next/router";

type SelectedLineItemContext = {
  childLineItems?: LineItem[];
  lineItem?: LineItem;
  loading: boolean;
  setLineItem: (lineItem: LineItem | Transaction) => void;
  selectedLoanId?: string;
  setSelectedLoanId: (loanId: string | undefined) => void;
  transaction?: Transaction;
  setTransaction: (transaction: Transaction) => void;
};

const SelectedLineItemContext = createContext<SelectedLineItemContext>({
  loading: false,
  setLineItem: () => {},
  setSelectedLoanId: () => {},
  setTransaction: () => {},
});

const isTransaction = (li?: LineItem | Transaction): li is Transaction => li?.hasOwnProperty("transaction_id") ?? false;

export const SelectedLineItemProvider = ({
  lineItem: inputLineItem,
  children,
}: {
  lineItem?: LineItem;
  children: ReactNode;
}) => {
  const router = useRouter();

  const selectedLoanId = typeof router.query?.loan_id === "string" ? router.query.loan_id : undefined;

  const [lineItem, setLineItem] = useState<LineItem | Transaction | undefined>(inputLineItem);
  const [transaction, setTransaction] = useState<Transaction | undefined>();
  const { rootLineItem, childLineItems, loading } = useLineItem(
    lineItem?.account_id,
    isTransaction(lineItem) ? lineItem?.transaction_id : lineItem?.line_item_id
  );

  // this is used as a dependency to setSelectedLoanId in order to prevent reinitialization on every render
  const strQuery = JSON.stringify(router.query);

  const setSelectedLoanId = useCallback(
    (loanId: string | undefined) => {
      if (loanId) {
        router.query.loan_id = loanId;
      } else {
        delete router.query.loan_id;
      }

      router.push(
        {
          query: router.query,
        },
        undefined,
        { shallow: true }
      );
    },
    [strQuery]
  );

  const value = {
    childLineItems,
    lineItem: rootLineItem,
    loading,
    setLineItem: (li: LineItem | Transaction) => {
      if (isTransaction(li)) {
        setTransaction(li);
      }
      setLineItem(li);
    },
    selectedLoanId,
    setSelectedLoanId,
    setTransaction,
    transaction,
  };

  return <SelectedLineItemContext.Provider value={value}>{children}</SelectedLineItemContext.Provider>;
};

export const useSelectedLineItem = () => {
  return useContext(SelectedLineItemContext);
};
