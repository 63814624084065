import React, { forwardRef } from "react";
import Router from "next/router";
import Head from "next/head";
import { identifyPage } from "@/libs/analytics";

Router?.events?.on?.("routeChangeComplete", (path) => {
  identifyPage(path);
});

const Page = forwardRef(({ title, children, ...rest }: any, ref) => {
  return (
    <div ref={ref} {...rest}>
      <Head>
        <title>{title}</title>
      </Head>
      {children}
    </div>
  );
});

export default Page;
