import React, { useCallback } from "react";
import { useApi, TApi } from "@/api/useApi";
import { mutate } from "swr";
import { Dropzone, useAuraMessages } from "@canopyinc/aura";

async function getPresignedUploadUrl(api: TApi, accountId: string, file: File) {
  const url = `/accounts/${accountId}/documents/upload_url`;

  // Reminder: do not use the body in a get request because historically GET did not support it.
  const { status, data } = await api.get(url, {
    params: { filename: file.name },
  });

  if (status !== 200) {
    throw new Error("Unexpected problem requesting upload url");
  }
  return data.upload_url;
}

interface UploadDropzoneProps {
  accountId: string;
}

const acceptedFilesChecker = (file) =>
  ["pdf", "jpg", "jpeg", "png", "csv", "odf", "heic"].some((ext) => file?.includes(ext));

export function UploadDropzone({ accountId, ...rest }: UploadDropzoneProps) {
  const api = useApi();
  const { addMessage } = useAuraMessages();

  const getUploadURL = async (file: File): Promise<string | null> => {
    if (acceptedFilesChecker(file?.type)) {
      return await getPresignedUploadUrl(api, accountId, file);
    }

    return null;
  };

  const onChange = async (e) => {
    try {
      const file: File = e.target.value?.[0];
      if (file) {
        // get the signed S3 upload url
        const uploadUrl = await getUploadURL(file);
        if (!uploadUrl) {
          throw new Error("Unable to upload file: invalid file extension");
        }
        // upload to S3
        const response = await fetch(uploadUrl, {
          method: "PUT",
          headers: {
            "Content-Type": file.type,
            "Content-Length": `${file.size}`,
          },
          body: file,
        });
        if (response.status > 200) {
          throw new Error();
        }
        e.target.value = "";
        mutate(`/accounts/${accountId}/documents/list`);
        addMessage({
          content: "File uploaded successfully",
          color: "success",
        });
      }
    } catch (error) {
      e.target.value = "";
      addMessage({
        content: error ?? "An error occurred while uploading the file",
        color: "danger",
      });
    }
  };

  return (
    <Dropzone
      classNames={{
        container: "mt-6",
      }}
      hint="Click or drag file here to upload"
      name="files"
      onChange={(e) => {
        onChange(e);
      }}
      {...rest}
    />
  );
}
