// ui

import { ComponentProps } from "react";
import { Card, CardBody, InputWrapper, Textarea } from "@canopyinc/aura";
import { Control, Controller, ValidationRule } from "react-hook-form";

export type InternalNoteProps = {
  classNames?: ComponentProps<typeof Card>["classNames"];
  inputName: string;
  placeholder?: string;
  title?: string;
  control?: Control<any, any>;
  rules?: {
    [key in string]: string | ValidationRule;
  };
  testid?: string;
};

export function InternalNoteField({
  classNames,
  control,
  inputName,
  rules,
  placeholder = "Write a note",
  title = "Internal Note",
  testid = "internal-note",
}: InternalNoteProps) {
  return (
    <Card title={title} size="sm" classNames={{ ...classNames }} testid={testid}>
      <CardBody>
        <InputWrapper htmlFor={inputName} labelText="" id={`${inputName}-wrapper`}>
          {control ? (
            <Controller
              name={inputName}
              control={control}
              render={({ field }) => (
                <Textarea rows={2} aria-label="Internal Note" placeholder={placeholder} {...field} name={inputName} />
              )}
              rules={rules}
            />
          ) : (
            <Textarea name={inputName} rows={2} aria-label="Internal Note" placeholder={placeholder} testid={testid} />
          )}
        </InputWrapper>
      </CardBody>
    </Card>
  );
}
