import { RecursivePartial } from "@/types";

export enum ReportType {
  transactions = "transactions",
  accounts = "accounts",
  reversals = "reversals",
  account_receivables_aging = "account_receivables_aging",
}

export enum ReportGranularity {
  account_level = "account_level",
  portfolio_level = "portfolio_level",
}

export type ReportRequest<T extends ReportType, P extends Record<string, any>> = {
  readonly report_type: T;
  report_parameters: P;
};

export type TransactionsReportRequest = ReportRequest<
  ReportType.transactions,
  {
    account_id?: string | number;
  }
>;

export type AccountsReportRequest = ReportRequest<ReportType.accounts, {}>;

export type ReversalsReportRequest = ReportRequest<
  ReportType.reversals,
  {
    account_id?: string | number;
  }
>;

export type AgingBucket = {
  label: string;
  lower: number;
  upper: number | "infinity";
};

export type AccountReceivablesAgingReportRequest = ReportRequest<
  ReportType.account_receivables_aging,
  {
    aging_buckets?: RecursivePartial<AgingBucket>[];
  }
>;

export type DownloadableReports =
  | TransactionsReportRequest
  | AccountsReportRequest
  | ReversalsReportRequest
  | AccountReceivablesAgingReportRequest;
