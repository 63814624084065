import { usePlugins } from "@/hooks/usePlugins";

/**
 * Determines the default route based on plugin configuration.
 * @returns The default route path based on the plugin's configuration.
 */
export const useMainPageRoute = () => {
  const plugins = usePlugins();
  const isAnalyticsDashboardEnabled = plugins.analytics_dashboard?.show_in_navigation;

  return isAnalyticsDashboardEnabled ? "/app/analytics-dashboard" : "/app/accounts";
};
