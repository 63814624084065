import { formatAmountCents, Text } from "@canopyinc/aura";
import cn from "classnames";

import { LineItem } from "@/types";
import { mdash } from "@/libs/string";
import { LINE_ITEM_STATUSES, LINE_ITEM_TYPES } from "@/constants";

export type TransactionAmountProps = {
  lineItem?: LineItem;
};

export const TransactionAmount = ({ lineItem }: TransactionAmountProps) => {
  const originalAmount =
    lineItem?.line_item_summary?.original_amount_cents != null
      ? formatAmountCents(lineItem?.line_item_summary?.original_amount_cents)
      : mdash;

  return (
    <Text
      testid="transaction-amount"
      classNames={{
        text: cn({
          "text-emerald-500":
            lineItem?.line_item_overview?.line_item_type === LINE_ITEM_TYPES.PAYMENT &&
            lineItem?.line_item_overview?.line_item_status === LINE_ITEM_STATUSES.VALID,
          "line-through":
            lineItem?.line_item_overview?.line_item_type === LINE_ITEM_TYPES.PAYMENT &&
            lineItem?.line_item_overview?.line_item_status === LINE_ITEM_STATUSES.INVALID,
        }),
      }}
    >
      {originalAmount}
    </Text>
  );
};
