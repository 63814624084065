import { FilterChange } from "@canopyinc/aura";

export const transformActiveFiltersToQueryObject = (activeFilters: FilterChange) => {
  const { filters } = activeFilters;
  const filterQuery = Object.entries(filters ?? {}).reduce((acc, [key, value]) => {
    // join arrays into comma separated strings
    const parsedValue = Array.isArray(value) ? value.join(",") : value;

    return {
      ...acc,
      [key]: parsedValue,
    };
  }, {});

  return {
    ...filterQuery,
  };
};
