import { useContext } from "react";

import I18nContext, { I18n } from "@/context/i18n";

/**
 * A custom hook to get an i18n translation function from I18nContext
 * @param basePath Base dictionary path to be applied to all i18n function calls, e.g. "accounts.list"
 * returns an i18n function, that if called with title, looks up "accounts.list.title" in dictionary
 * @returns an I18n translate function
 */
export const useI18n = (basePath?: string): I18n => {
  const { dictionary, i18n, ready } = useContext(I18nContext);

  return {
    dictionary,
    ready,
    i18n: (key: string) => i18n(basePath ? `${basePath}.${key}` : key),
  };
};

export const useDictionary = (): I18n["dictionary"] => {
  const { dictionary } = useContext(I18nContext);
  return dictionary;
};
