import useSWRImmutable from "swr/immutable";
import { TApi, useApi } from "@/api/useApi";

export type TAPIUser = {
  /**
   * The unique ID in Canopy for this API user
   */
  api_user_id: string;
  /**
   * The email address of this API User
   */
  email: string;
  /**
   * The first name of the API user.
   */
  name_first?: string;
  /**
   * The last name of the API user.
   */
  name_last?: string;
  /**
   * The organization to which the API user belongs.
   */
  organization_name: string;
  /**
   * Phone number of the API user
   */
  phone?: string;
  /**
   * The role of this user in the API
   *
   * String equivalent:
   * 1. "superadmin"
   * 2. "qa"
   * 3. "admin"
   * 4. "servicing"
   * 5. "operations"
   * 6. "superadmin_m2m"
   * 7. "qa_m2m"
   * 8. "admin_m2m"
   * 9. "servicing_m2m"
   */
  role: number;
};

/**
 * Admin role integers
 */
const adminRoles = [1, 2, 3];

/**
 * https://docs.canopyservicing.com/#operation-get-api_users-summary
 */
export const getAPIUserSummary = async (api: TApi): Promise<TAPIUser> => {
  return api.get(`/api_users/summary`).then((res) => res.data);
};

/**
 * Fetches the current user's summary
 */
export function useCurrentUser(props: { skip?: boolean } = { skip: false }) {
  const api = useApi();

  function keyGetter() {
    if (props.skip) {
      return null;
    }

    return `/api_users/summary`;
  }

  const { data, error, mutate } = useSWRImmutable(keyGetter, () => getAPIUserSummary(api));

  return {
    user: data,
    error,
    loading: !data && !error,
    refetch: mutate,
    userIsAdmin: data && adminRoles.includes(data.role),
  };
}
