import { Card, DataGrid, DataGridColumn, Text, formatAmountCents, formatMMDDYY } from "@canopyinc/aura";

import { TransactionDetailsProps } from "..";
import { useI18n, usePluginColumns, usePlugins } from "@/hooks";
import { Transaction } from "@canopyinc/api-docs/types/ts/Transaction.type";
import { TransactionDropdown } from "@/components/Transactions/TransactionDropdown";
import { useTransactionById } from "@/api/accounts/useTransactionById";
import { mdash } from "@/libs/string";

type ReversalSummaryProps = TransactionDetailsProps & {
  columns: DataGridColumn[];
  reversal?: Transaction;
  title: string;
};

export const BaseReversalSummary = ({ columns, loading, reversal, timeZone, title }: ReversalSummaryProps) => {
  const DropdownComponent = () => <TransactionDropdown onClick={() => {}} onSelect={() => {}} transaction={reversal} />;

  const rows = [
    {
      ...reversal,
      date: reversal?.issued_at != null ? formatMMDDYY(reversal.issued_at, { timeZone }) : null,
      amount: reversal?.original_amount_cents != null ? formatAmountCents(reversal?.original_amount_cents) : mdash,
      action: DropdownComponent,
    },
  ];
  return (
    <div className="flex flex-col gap-2">
      <Text as="h3">{title}</Text>
      <Card>
        <DataGrid columns={columns} loading={loading} rows={rows} />
      </Card>
    </div>
  );
};

export const ReversalSummary = (props: TransactionDetailsProps) => {
  const rootPlugins = usePlugins();
  const plugins = rootPlugins?.components?.transaction_details?.reversal_summary;
  const columns = usePluginColumns(plugins?.fields);

  const { dictionary: rootDictionary } = useI18n();
  const dictionary = rootDictionary?.components?.transaction_details?.reversal_summary;
  const title = dictionary?.title ?? "Payment Reversal";

  const { account, transaction } = props;
  const reversalId = transaction?.reversed_by_transaction_id ?? transaction?.adjustment_by_transaction_id;
  const { transaction: reversal, loading } = useTransactionById(account.account_id, reversalId);

  return <BaseReversalSummary columns={columns} title={title} {...props} loading={loading} reversal={reversal} />;
};
