import { ReactNode, createContext, useContext, useState } from "react";

import { UseTransactionByIdOutput, useTransactionById } from "@/api/accounts/useTransactionById";
import { useLineItem } from "@/api/accounts/useLineItem";
import { LineItem } from "@/types";

export type TransactionContext = {
  transactionId?: string;
  setTransactionId: (transactionId: string) => void;
  adjustmentId?: string;
  setAdjustmentId: (adjustmentId?: string) => void;
  transaction: UseTransactionByIdOutput;
  adjustment: UseTransactionByIdOutput;
  lineItem: {
    rootLineItem?: LineItem;
    childLineItems?: LineItem[];
    loading: boolean;
  };
};

const SelectedTransactionContext = createContext<TransactionContext>({
  setTransactionId: () => {},
  setAdjustmentId: () => {},
  transaction: {
    transaction: undefined,
    loading: false,
    error: false,
  },
  adjustment: {
    transaction: undefined,
    loading: false,
    error: false,
  },
  lineItem: {
    rootLineItem: undefined,
    childLineItems: [],
    loading: false,
  },
});

type SelectedTransactionProviderProps = {
  accountId?: string;
  children: ReactNode;
};

export const SelectedTransactionProvider = ({ accountId, children }: SelectedTransactionProviderProps) => {
  const [transactionId, setTransactionId] = useState<string | undefined>();
  const [adjustmentId, setAdjustmentId] = useState<string | undefined>();
  const { transaction, loading, error } = useTransactionById(accountId, transactionId);
  const {
    transaction: adjustment,
    loading: adjustmentLoading,
    error: adjustmentError,
  } = useTransactionById(accountId, adjustmentId);

  // TODO remove this once legacy Transactions list is removed
  const lineItem = useLineItem(accountId, transactionId);

  const value = {
    transactionId,
    setTransactionId,
    adjustmentId,
    setAdjustmentId,
    transaction: {
      transaction,
      loading,
      error,
    },
    adjustment: {
      transaction: adjustment,
      loading: adjustmentLoading,
      error: adjustmentError,
    },
    lineItem,
  };

  return <SelectedTransactionContext.Provider value={value}>{children}</SelectedTransactionContext.Provider>;
};

export const useSelectedTransaction = () => useContext(SelectedTransactionContext);
