import { Tooltip } from "@canopyinc/aura";
import { createRef } from "react";

import { useHasOverflow } from "@/hooks/useHasOverflow";

export const TransactionId = ({ id }: { id: string | number }) => {
  const ref = createRef<HTMLDivElement>();
  const hasOverflow = useHasOverflow(ref);

  const text = (
    <div ref={ref} className="text-ellipsis max-w-[12rem] overflow-hidden">
      {id}
    </div>
  );

  return hasOverflow ? (
    <Tooltip content={<div>{id}</div>} classNames={{ panel: "!max-w-[48rem]" }}>
      {text}
    </Tooltip>
  ) : (
    <div className="max-w-[12rem] text-ellipsis overflow-hidden">{text}</div>
  );
};
