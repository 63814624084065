import { BalanceSplit } from "@canopyinc/api-docs/types/ts/BalanceSplit.type";
import { Transaction } from "@canopyinc/api-docs/types/ts/Transaction.type";
import { Button, Card, DataGrid, FilterChange, Text, formatAmountCents, formatMMDDYY } from "@canopyinc/aura";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { formatInTimeZone } from "date-fns-tz";
import { useState } from "react";

import { GetTransactionSplitsQuery, useTransactionSplits } from "@/api/accounts/useTransactionSplits";
import { StatefulPagination } from "@/components";
import { DATE_TIME_FORMAT_12_HOUR_WITH_TZ, LINE_ITEM_TYPES } from "@/constants";
import { usePluginColumns, usePlugins } from "@/hooks";
import { mdash } from "@/libs/string";
import { transformActiveFiltersToQueryObject } from "./utils";
import { DropdownOption, ViewOtherSplitsButton } from "./ViewOtherSplitsButton";
import { TransactionDetailsProps } from "..";

export type BalanceSplitProps = {
  accountCertifiedTime?: string;
  error?: boolean;
  splits: BalanceSplit[];
  repourDates: string[];
  loading: boolean;
  onFilter: (filters: GetTransactionSplitsQuery) => void;
  onSelect?: (split: BalanceSplit) => void;
  timeZone?: string;
  transaction?: Transaction;
};

// Lower order React component that handles rendering logic
// This is used directly by LoanLab where data is passed as a prop from the aggregate
export const BaseBalanceSplits = ({
  accountCertifiedTime,
  splits,
  loading,
  onFilter,
  onSelect,
  repourDates,
  timeZone,
  transaction,
}: BalanceSplitProps) => {
  const isTxParentCapitalizationItem =
    transaction?.transaction_type === LINE_ITEM_TYPES.CAPITALIZATION_OF_INTEREST_AND_FEES;

  const rootPlugins = usePlugins();
  const plugins = rootPlugins?.components?.transaction_details?.balance_splits;
  const baseColumns = usePluginColumns(plugins?.fields);
  const columns = isTxParentCapitalizationItem ? baseColumns.filter((c) => c.key !== "action") : baseColumns;

  const rows =
    splits?.map((split) => {
      return {
        ...split,
        relevant_transaction_id:
          transaction?.transaction_super_type === "PAYMENT"
            ? split.applied_to_transaction_id
            : transaction?.transaction_type == LINE_ITEM_TYPES.CAPITALIZATION_OF_INTEREST_AND_FEES
            ? split.balance_split_id
            : split.origination_transaction_id,
        issued_at: formatMMDDYY(split.issued_at),
        amount_cents: split.amount_cents != null ? formatAmountCents(split.amount_cents) : mdash,
        action: <Button iconOnly size="sm" variant="ghost" icon={<FontAwesomeIcon icon={faChevronRight} />} />,
      };
    }) ?? [];

  const [selection, setSelection] = useState<string | undefined>(accountCertifiedTime);
  const formattedDateSelection = formatInTimeZone(
    selection ?? new Date().toISOString(),
    timeZone ?? "UTC",
    DATE_TIME_FORMAT_12_HOUR_WITH_TZ
  );
  const asOfOrBefore = selection != null ? "before" : "as of";
  const hasRepourDates = repourDates != null && repourDates.length > 0;

  // build dropdown options
  const options: DropdownOption[] = hasRepourDates
    ? [
        { label: "View Current", filters: { is_discarded: false } },
        ...repourDates.map((date) => {
          return {
            label: `View Before ${formatInTimeZone(date, timeZone ?? "UTC", DATE_TIME_FORMAT_12_HOUR_WITH_TZ)}`,
            value: date,
            filters: { active_before: date },
          };
        }),
      ]
    : [];

  return (
    <div data-testid="balance-splits">
      <div className="flex flex-col gap-2">
        <>
          <div className="flex flex-row justify-between">
            <Text as="h3">Balance Splits</Text>
            {hasRepourDates ? (
              <ViewOtherSplitsButton
                dropdownOptions={options}
                onSelect={({ filters, value }) => {
                  setSelection(value);
                  onFilter(filters);
                }}
                timeZone={timeZone}
              />
            ) : null}
          </div>
          <Text as="p" classNames={{ text: "-mt-2" }}>
            <em>
              {asOfOrBefore} {formattedDateSelection}
            </em>
          </Text>
        </>

        <Card>
          <DataGrid
            columns={columns}
            loading={loading}
            rows={rows ?? []}
            // @ts-expect-error terrible Aura typing
            onRowClick={onSelect}
            testid="balance-splits"
          />
        </Card>
      </div>
    </div>
  );
};

type PaginationState = {
  pageNumber: number;
  pagination: GetTransactionSplitsQuery;
};

// A higher order React component handles fetching logic
// This component is used in CanopyOS views where components self-serve data
export const BalanceSplits = ({
  onSelectLoanId,
  onSelectTransactionId,
  timeZone,
  transaction,
}: TransactionDetailsProps) => {
  const [activeFilters, setActiveFilters] = useState<FilterChange>({
    search: "",
    filters: {},
  });
  const [{ pageNumber, pagination }, setPaginationState] = useState<PaginationState>({
    pageNumber: 1,
    pagination: {},
  });

  const onNext = () =>
    setPaginationState((prev) => {
      return {
        pageNumber: prev.pageNumber + 1,
        pagination: { starting_after: currentPage.currentPage?.starting_after },
      };
    });
  const onPrevious = () =>
    setPaginationState((prev) => {
      return {
        pageNumber: prev.pageNumber > 1 ? prev.pageNumber - 1 : 1,
        pagination: { ending_before: currentPage.currentPage?.ending_before },
      };
    });
  const onFilter = (filters: FilterChange) => {
    // ensure we reset pagination state when changing to another set of historical splits
    setPaginationState({ pageNumber: 1, pagination: {} });
    setActiveFilters(filters);
  };

  const {
    splits,
    pagination: currentPage,
    error,
    loading,
    repourDates,
  } = useTransactionSplits(
    transaction?.account_id,
    transaction?.transaction_id,
    {
      ...pagination,
      ...transformActiveFiltersToQueryObject(activeFilters),
    },
    pageNumber
  );

  return (
    <div className="flex flex-col gap-2 -pb-2">
      <BaseBalanceSplits
        splits={splits}
        repourDates={repourDates ?? []}
        error={error}
        loading={loading}
        onFilter={(filters) =>
          onFilter({
            search: "",
            // @ts-expect-error terrible Aura typing
            filters,
          })
        }
        onSelect={(split) => {
          if (transaction?.transaction_type !== LINE_ITEM_TYPES.PAYMENT) {
            return onSelectTransactionId?.(split.origination_transaction_id);
          }
          if (split.applied_to_transaction_type === LINE_ITEM_TYPES.LOAN) {
            return onSelectLoanId?.(split.applied_to_transaction_id);
          }
          return onSelectTransactionId?.(split.applied_to_transaction_id);
        }}
        timeZone={timeZone}
        transaction={transaction}
      />
      {<StatefulPagination {...currentPage} loading={loading} onNext={onNext} onPrevious={onPrevious} />}
    </div>
  );
};
