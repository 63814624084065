import React from "react";

/** Creates a forwardable ref and a handler to focus the ref input */
export function useAutofocus() {
  const ref = React.createRef<any>();

  function setFocus() {
    if (ref.current && ref.current.focus) {
      ref.current.focus();
    }
  }

  return {
    ref,
    setFocus,
  };
}
