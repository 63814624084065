import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

type StatusIconType = {
  status: "unpaid" | "paid" | "underpaid" | "unpaid_neutral";
};

/**
 * Renders a gray circle, a green check mark, or an orange check mark
 */
export const StatusIcon = ({ status }: StatusIconType) => {
  switch (status) {
    case "unpaid_neutral":
      return <div className="bg-gray-300 w-5 h-5 rounded-full" />;
    case "unpaid":
      return <FontAwesomeIcon icon={faXmarkCircle} className="text-red-500" />;
    case "underpaid":
      return <FontAwesomeIcon icon={faCheckCircle} className="text-amber-500" />;
    case "paid":
      return <FontAwesomeIcon icon={faCheckCircle} className="text-emerald-500" />;
  }
};
