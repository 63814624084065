import useSWR from "swr";
import { TApi, useApi } from "@/api/useApi";
import { LineItem } from "@/types";
import { TListResp } from "../api-types";
import Decimal from "decimal.js-light";

export type TLineItemsResp = TListResp<LineItem>;

/**
 * https://bump.sh/doc/canopy-in-progress-documentation#operation-get-accounts-parameter-line_items
 */
const getLineItem = async (api: TApi, account_id: string, line_item_id: string | number) => {
  return api
    .get<TLineItemsResp>(`/accounts/${account_id}/line_items/${line_item_id}?limit=1000`)
    .then((res) => res.data);
};

/**
 * Fetches a specific line item with all its children line items
 */
export function useLineItem(account_id?: string, line_item_id?: string | number) {
  const api = useApi();
  const { data, error } = useSWR(
    () =>
      account_id && line_item_id
        ? [`/accounts/${account_id}/line_items${line_item_id}`, account_id, line_item_id]
        : null,
    (_, account_id, line_item_id) => {
      return getLineItem(api, `${account_id}`, `${line_item_id}`);
    },
    { refreshInterval: 30_000 } // 30 seconds
  );

  const results = data?.results;

  return {
    rootLineItem: results?.find((li) => li.line_item_id == line_item_id),
    childLineItems: results?.filter((li) => li.line_item_id !== line_item_id),
    error: error,
    loading: !error && !results,
  };
}
