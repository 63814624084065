import { Tag, TagProps } from "@canopyinc/aura";

import { LINE_ITEM_STATUSES, LINE_ITEM_TYPES } from "@/constants";
import { capitalize, mdash } from "@/libs/string";
import { LineItem, LineItemStatus, LineItemType } from "@/types";
import { PreviewLineItem } from "@/api/accounts/accountPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { RecursivePartial } from "@/types";
import { isRestructuredLoan } from "@/utils/loan";
import { LoanFromRestructureTag, RestructuredLoanTag } from "@/components/LoanTags";

export type TransactionStatusProps = {
  lineItem?: LineItem;
  isFromRestructure?: boolean;
};

export type PreviewStatusProps = {
  lineItem?: RecursivePartial<PreviewLineItem>;
};

export const LINE_ITEM_STATUS_COLORS: {
  [key in string]: TagProps["color"];
} = {
  [LINE_ITEM_STATUSES.AUTHORIZED]: "warning",
  [LINE_ITEM_STATUSES.DECLINED]: "danger",
  [LINE_ITEM_STATUSES.INVALID]: "danger",
  [LINE_ITEM_STATUSES.PENDING]: "warning",
  [LINE_ITEM_STATUSES.PROCESSING]: "default",
  [LINE_ITEM_STATUSES.REVERSED]: "default",
  [LINE_ITEM_STATUSES.VOID]: "danger",
  [LINE_ITEM_STATUSES.REFINANCED]: "default",
  RESTRUCTURED: "default", // no longer a line item status
} as const;

// This is a map of line_item_types to line_item_statuses which persist only until next roll time
const TRANSIENT_STATUSES_BY_LINE_ITEM_TYPE: {
  [key in LineItemType]?: LineItemStatus[];
} = {
  [LINE_ITEM_TYPES.PAYMENT_REVERSAL]: [LINE_ITEM_STATUSES.PROCESSING],
};

export const hasTransientStatus = ({ type, status }: { type?: string | null; status?: string | null }) => {
  return (
    type != null &&
    status != null &&
    Object.keys(TRANSIENT_STATUSES_BY_LINE_ITEM_TYPE)?.includes(type) &&
    TRANSIENT_STATUSES_BY_LINE_ITEM_TYPE[type]?.includes(status)
  );
};

export const LineStatus = ({ type, status }: { type?: string; status: string }) => {
  return Object.keys(LINE_ITEM_STATUS_COLORS).includes(status) ? (
    <div className="flex flex-row gap-3">
      <Tag
        testid="line-item-status"
        size="sm"
        color={LINE_ITEM_STATUS_COLORS[status] ?? "default"}
        classNames={{
          tag: "inline",
        }}
      >
        {status ? capitalize(status) : mdash}
      </Tag>
      {hasTransientStatus({ type, status }) && (
        <div data-testid="line-item-status-spinner">
          <FontAwesomeIcon icon={faSpinnerThird} className="fa-spin" />
        </div>
      )}
    </div>
  ) : null;
};

export const PreviewStatus = ({ lineItem }: PreviewStatusProps) => {
  const type = lineItem?.line_item_type;
  const isRestructured = lineItem?.restructure_to_line_item_id != null;
  const status = lineItem?.line_item_status ?? mdash;

  return isRestructured ? <RestructuredLoanTag /> : <LineStatus type={type} status={status} />;
};

export const TransactionStatus = ({ lineItem, isFromRestructure }: TransactionStatusProps) => {
  const type = lineItem?.line_item_overview?.line_item_type;
  const isRestructured = lineItem && isRestructuredLoan(lineItem);
  const status = lineItem?.line_item_overview?.line_item_status ?? mdash;

  return (
    <div className="flex">
      {isFromRestructure ? <LoanFromRestructureTag /> : null}
      {isRestructured ? <RestructuredLoanTag /> : <LineStatus type={type} status={status} />}
    </div>
  );
};
