import { Text } from "@canopyinc/aura";

import { formatTransactionDescription } from "@/utils/lineItem";
import { LineItem } from "@/types";

export type TransactionDescriptionProps = {
  lineItem?: LineItem;
};

export const TransactionDescription = ({ lineItem }: TransactionDescriptionProps) => {
  const type = lineItem?.line_item_overview?.line_item_type;
  const allocation = lineItem?.line_item_overview?.allocation;
  const merchantName = lineItem?.merchant_data?.name;

  return (
    <Text testid="transaction-description">{formatTransactionDescription({ type, allocation, merchantName })}</Text>
  );
};
