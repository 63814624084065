import { Loan } from "@canopyinc/api-docs/types/ts/Loan.type";
import useSWR from "swr";

import { TApi, useApi } from "@/api/useApi";

const getLoanById = async (api: TApi, url: string) => {
  const response = await api.get<Loan>(url);
  return response.data;
};

/**
 * Fetches loan by account and loan ID
 */
export function useGetLoan({ accountId, loanId }: { accountId?: string; loanId?: string }) {
  const api = useApi();

  const {
    data: loan,
    error,
    mutate,
  } = useSWR(
    () => (accountId != null && loanId != null ? `/accounts/${accountId}/line_items/loans/${loanId}` : null),
    (url: string) => getLoanById(api, url)
  );

  return {
    error,
    loan,
    loading: !error && !loan,
    refetchLoans: mutate,
  };
}
