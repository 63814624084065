import { isAfter, isBefore, isEqual, sub } from "date-fns";

import { Account } from "@/api/accounts/account";

type GracePeriodInfo = {
  withinGrace: boolean;
  approachingDue: boolean;
  withinUrgentGrace: boolean;
  gracedDueDate: string | null;
};

export const defaultGraceInfo = {
  withinGrace: false,
  approachingDue: false,
  withinUrgentGrace: false,
  gracedDueDate: null,
};

export const gracePeriodInfo = (account: Account, now: Date = new Date()): GracePeriodInfo => {
  const accountStatus = account.account_overview?.account_status;
  const dueDateStr = account.min_pay_due_cents?.min_pay_due_at;
  const gracedDueDateStr: string | null = account.min_pay_due_cents?.graced_due_date ?? null;
  const hasGracePeriod = !!(
    gracedDueDateStr &&
    dueDateStr &&
    gracedDueDateStr !== dueDateStr &&
    accountStatus === "ACTIVE"
  );
  if (!hasGracePeriod) return defaultGraceInfo;

  const due = new Date(dueDateStr);
  const dayBeforeDue = sub(due, { days: 1 });
  const gracedDue = new Date(gracedDueDateStr);
  const dayBeforeGracedDue = sub(gracedDue, { days: 1 });

  const withinGrace = (isEqual(due, now) || isAfter(now, due)) && isBefore(now, gracedDue);
  const approachingDue = (isEqual(dayBeforeDue, now) || isAfter(now, dayBeforeDue)) && isBefore(now, due);
  const withinUrgentGrace =
    (isEqual(dayBeforeGracedDue, now) || isAfter(now, dayBeforeGracedDue)) && isBefore(now, gracedDue);

  return {
    withinGrace,
    approachingDue,
    withinUrgentGrace,
    // return the graced due date string to be consistent with the other date values from the Account
    gracedDueDate: gracedDueDateStr,
  };
};
