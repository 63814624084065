/**
 * *
 * Here lay all our consolidated string helprs.
 * Commonly used are the money formatters.
 * *
 */

/**
 * Regular Expression to match words/letters from a string
 *
 * It will treat any letter or number separated by a space,
 * special character as a separate word.
 *
 * For a detailed breakdown/explanation
 * see: https://regex101.com/r/xZU8Ez/1
 *
 * @note this only works with ASCII strings
 *
 * @examples
 *
 * * "HELLO_WORLD".match(ABSTRACT_WORDS_REGEX) -> ["HELLO", "WORLD"]
 * * "#HELLO#WORLD#".match(ABSTRACT_WORDS_REGEX) -> ["HELLO", "WORLD"]
 * * "HELLO123WORLD".match(ABSTRACT_WORDS_REGEX) -> ["HELLO", "123", "WORLD"]
 * * "HELLO\nWORLD".match(ABSTRACT_WORDS_REGEX) -> ["HELLO", "WORLD"]
 */
export const ABSTRACT_WORDS_REGEX =
  /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/gm;

// Quick access em-dash/hyphen (e.g., "—")
export const mdash = String.fromCharCode(8212);

// Extracts initials from a name
export const getInitials = (name = "") =>
  name
    .replace(/\s+/, " ")
    ?.split(" ")
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join("");

/** Convert whole number to percentage */
export const formatPercentage = (percent: number) => {
  return percent.toLocaleString(undefined, { style: "percent", minimumFractionDigits: 2 });
};

// See https://www.twilio.com/docs/glossary/what-e164
//
// 415-555-2671 -> +14155552671
export const e164ify = (input: string): string => {
  const numbersOnly = input.replaceAll("-", "");
  return `+1${numbersOnly}`;
};

/**
 * Capitalize each word of a string
 *
 * It will treat subsequent letters or similarly numbers
 * as a whole word, while spaces or special characters
 * are treated as word separators. See examples.
 *
 * @note this only works with ASCII strings
 *
 * @examples
 *
 * * capitalize("hello world") -> "Hello World"
 * * capitalize("HELLO_WORLD") -> "Hello World"
 * * capitalize("#HELLO#WORLD#") -> "Hello World"
 * * capitalize("HELLO123WORLD") -> "Hello 123 World"
 * * capitalize("HELLO\nWORLD") -> "Hello World"
 */
export const capitalize = (input: string): string | undefined => {
  return (
    input
      ?.match(ABSTRACT_WORDS_REGEX)
      ?.reduce((acc: string, value: string): string => {
        // convert this given word to all lowercase
        const lowercase = value.toLowerCase();
        // convert first letter to upper case and
        // append the that value back to the full string
        return `${acc} ${lowercase.substr(0, 1).toUpperCase()}${lowercase.substr(1)}`;
      }, "")
      // It will initially return as " The Value"
      // so we trim the white space
      ?.trim()
  );
};

export const kebabCase = (input: string): string =>
  input
    .replace(/([a-z])([A-Z])/g, "$1_$2")
    .replace(/[\s-]+/g, "_")
    .toLowerCase();

export const isEmptyString = (val?: string) => val == null || val.trim().length === 0;

export const emptyStringToNull = (val?: string) => (isEmptyString(val) ? null : val);

// Convert a string to title case, keeping non-alphanumeric characters
// other than converting underscores to spaces
export function titleCase(str: string): string {
  const noUndercores = str.replace(/_{1,}/g, " ");
  const trimmed = noUndercores.trim();
  return trimmed.replace(/\b\w+/g, (match) => {
    // Convert all-caps variable names to title case
    if (match === match.toUpperCase()) {
      return match
        .split("_")
        .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`)
        .join(" ");
    }
    // Title-case each word. Avoiding lodash as it strips non-alphanumerics entirely
    return `${match.charAt(0).toUpperCase()}${match.slice(1).toLowerCase()}`;
  });
}
