import { RefObject, useLayoutEffect, useState } from "react";

export const useHasOverflow = (ref: RefObject<HTMLElement>) => {
  const [hasOverflow, setHasOverflow] = useState<boolean>(false);

  useLayoutEffect(() => {
    const { current } = ref;

    if (current != null) {
      setHasOverflow(current?.scrollWidth > current?.clientWidth);
    }
  }, [ref]);

  return hasOverflow;
};
