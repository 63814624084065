import { Tag, Tooltip } from "@canopyinc/aura";

import { TransactionFieldProps } from "../Transactions/TransactionTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft } from "@fortawesome/pro-solid-svg-icons";

export const ReversedPaymentIndicator = ({ transaction }: TransactionFieldProps) => {
  return (
    <>
      {transaction?.reversed_at != null ? (
        <Tooltip content={`Reversed by ${transaction.reversed_by_transaction_id}`}>
          <Tag
            testid="reversed-payment-indicator"
            size="sm"
            color="default"
            classNames={{
              tag: "inline cursor-pointer",
            }}
          >
            {transaction?.reversed_at != null ? <FontAwesomeIcon className="mr-2" icon={faClockRotateLeft} /> : null}
            Reversed
          </Tag>
        </Tooltip>
      ) : null}
    </>
  );
};
