import React, { createContext, useEffect, useState } from "react";
import mergeWith from "lodash/mergeWith";
import merge from "lodash/mergeWith";

import { RecursivePartial } from "@/types";
import { CanopyOsPluginsSchema, FieldDefinition } from "./types";
import axios from "axios";

type PluginsProviderProps = {
  assetsUrl: string;
  children: React.ReactNode;
  plugins?: RecursivePartial<CanopyOsPluginsSchema>;
};

export type PluginsSchema = CanopyOsPluginsSchema;

const PluginsContext = createContext<RecursivePartial<CanopyOsPluginsSchema>>({});

export function mergeByKeyCustomizer(base, override) {
  if (!Array.isArray(base) || base.length <= 0) return;
  if (typeof base[0] !== "object" || !Object.hasOwn(base[0], "key")) return;

  const joinedEntries = Object.values(base.concat(override));
  const mergedEntryMap = joinedEntries.reduce((acc: Map<string, FieldDefinition>, current: FieldDefinition) => {
    if (current.key !== undefined) {
      acc.set(current.key, mergeWith(acc.get(current.key) ?? {}, current, mergeByKeyCustomizer));
    }
    return acc;
  }, new Map());
  return Array.from(mergedEntryMap.values());
}

export const PluginsProvider = ({ assetsUrl, plugins: inputPlugins, children }: PluginsProviderProps) => {
  const [plugins, setPlugins] = useState<RecursivePartial<CanopyOsPluginsSchema>>(inputPlugins ?? {});

  useEffect(() => {
    const fetchPlugins = async () => {
      const responses = await Promise.all([
        axios(`${assetsUrl}/canopy-os/plugins_base.json`),
        axios(`${assetsUrl}/canopy-os/plugins_custom.json`),
      ]);

      const [basePlugins, customPlugins] = await Promise.all(
        responses.map(async (response) => {
          if (response.status === 200) {
            return response.data as any;
          }
        })
      );

      try {
        setPlugins(mergeWith(basePlugins, customPlugins, mergeByKeyCustomizer));
      } catch (error) {
        console.error("Error occurred merging plugins using `Key`, falling back to positional merge.", { error });
        setPlugins(merge(basePlugins, customPlugins));
      }
    };
    fetchPlugins();
  }, []);

  return <PluginsContext.Provider value={plugins}>{children}</PluginsContext.Provider>;
};

export default PluginsContext;
