import { TApi } from "@/api/useApi";
import { TMutationOpts, useMutation } from "@/hooks/useMutation";
import { DownloadableReports } from "@/types/reports";

export async function downloadReport(api: TApi, report: DownloadableReports) {
  const response = await api.post("/internal/reports", { report }, { responseType: "blob" });
  const [, format] = response.headers["content-type"]?.split("/") ?? [];
  const [, filename] = response.headers["content-disposition"]?.split("filename=") ?? [];
  const url = window.URL.createObjectURL(response.data);
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = filename ?? `report.${format ?? "text"}`;
  document.body.appendChild(anchor);
  anchor.click();
  anchor.remove();
}

/**
 * Hook for downloading reports
 *
 * @returns Promise<void>
 */
export function useDownloadReport(opts?: TMutationOpts) {
  return useMutation<void, DownloadableReports>(downloadReport, opts);
}
