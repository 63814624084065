import { Transaction } from "@canopyinc/api-docs/types/ts/Transaction.type";

import { DRAWERS, DrawerType } from "@/context/AccountDrawersContext/types";
import { Button, Dropdown, Menu, MenuItem } from "@canopyinc/aura";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons";
import { CanopyOsPluginsSchema } from "@/context/plugins/types";
import { CanopyOsI18NSchema } from "@/context/i18n/types/generatedTypes";
import { LINE_ITEM_STATUSES, LINE_ITEM_TYPES } from "@/constants";
import { RecursivePartial } from "@/types";
import { usePlugins } from "@/hooks";

type TransactionDropdownProps = {
  dictionary?: RecursivePartial<CanopyOsI18NSchema["components"]["transactions_view"]["list"]["dropdown"]>;
  onClick?: (action: Partial<DrawerType>) => void;
  onSelect?: (transaction: Transaction, expected_drawer: Partial<DrawerType>) => void;
  plugins?: RecursivePartial<CanopyOsPluginsSchema["components"]["transactions_view"]["list"]["dropdown"]>;
  transaction?: Transaction;
};

export const TransactionDropdown = ({
  dictionary,
  onClick,
  onSelect,
  plugins,
  transaction,
}: TransactionDropdownProps) => {
  const rootPlugins = usePlugins();

  const type = transaction?.transaction_type;
  const status = transaction?.transaction_status;

  // Old transaction details view (and backing API) does not support interest transactions
  const showButton =
    rootPlugins?.components?.transaction_details?.enabled || transaction?.transaction_super_type !== "INTEREST";

  const isEditableCharge = type === LINE_ITEM_TYPES.CHARGE && status === LINE_ITEM_STATUSES.AUTHORIZED;
  const isEditablePayment = type === LINE_ITEM_TYPES.PAYMENT && status === LINE_ITEM_STATUSES.PENDING;
  const canChangeStatus = isEditableCharge || isEditablePayment;

  const hasReversal = transaction?.reversed_at != null;
  const isRestructured = transaction?.restructured_to_loan_id != null;
  const canReversePayment = plugins?.reverse_payment?.enabled && type === LINE_ITEM_TYPES.PAYMENT;
  const canReverseOffset = plugins?.reverse_offset?.enabled && type === LINE_ITEM_TYPES.DEBIT_OFFSET;

  return showButton ? (
    <div className="flex justify-end">
      <Dropdown
        trigger={
          <Button
            testid="transaction-dropdown-trigger"
            iconOnly
            size="sm"
            variant="ghost"
            icon={<FontAwesomeIcon icon={faAngleDown} />}
          >
            Menu
          </Button>
        }
      >
        <Menu stacked>
          {plugins?.details?.enabled ? (
            <MenuItem
              testid="transaction-details-menu-item"
              key="transactionDetails"
              onClick={() => {
                const drawer = DRAWERS.TRANSACTION_DETAILS;
                transaction != null && onSelect?.(transaction, drawer);
                onClick?.(drawer);
              }}
            >
              {dictionary?.details}
            </MenuItem>
          ) : null}
          {plugins?.dispute_charge?.enabled && type === "CHARGE" ? (
            <>
              <MenuItem
                testid="dispute-charge-menu-item"
                key="disputeCharge"
                onClick={() => {
                  const drawer = DRAWERS.DISPUTE_CHARGE;
                  transaction != null && onSelect?.(transaction, drawer);
                  onClick?.(drawer);
                }}
              >
                {dictionary?.dispute_charge}
              </MenuItem>
            </>
          ) : null}
          {canReverseOffset && !hasReversal ? (
            <MenuItem
              testid="reverse-offset-menu-item"
              key="reverseOffset"
              onClick={() => {
                const drawer = DRAWERS.REVERSE_PAYMENT;
                transaction != null && onSelect?.(transaction, drawer);
                onClick?.(drawer);
              }}
            >
              {dictionary?.reverse_offset}
            </MenuItem>
          ) : null}
          {canReversePayment && !hasReversal ? (
            <MenuItem
              testid="reverse-payment-menu-item"
              key="reversePayment"
              onClick={() => {
                const drawer = DRAWERS.REVERSE_PAYMENT;
                transaction != null && onSelect?.(transaction, drawer);
                onClick?.(drawer);
              }}
            >
              {dictionary?.reverse_payment}
            </MenuItem>
          ) : null}
          {plugins?.waive_fee?.enabled && type === "FEE" ? (
            <MenuItem
              testid="waive-fee-menu-item"
              key="waiveFee"
              onClick={() => {
                const drawer = DRAWERS.WAIVE_FEE;
                transaction != null && onSelect?.(transaction, drawer);
                onClick?.(drawer);
              }}
            >
              {dictionary?.waive_fee}
            </MenuItem>
          ) : null}
          {plugins?.edit_status?.enabled && canChangeStatus ? (
            <MenuItem
              testid="edit-status-menu-item"
              key="editStatus"
              onClick={() => {
                const drawer = DRAWERS.EDIT_STATUS;
                transaction != null && onSelect?.(transaction, drawer);
                onClick?.(drawer);
              }}
            >
              {dictionary?.edit_status}
            </MenuItem>
          ) : null}
          {plugins?.offset_loan?.enabled && type === "LOAN" && isRestructured ? (
            <MenuItem
              testid="offset-loan-menu-item"
              key="offsetLoan"
              onClick={() => {
                if (transaction != null) {
                  onSelect?.(transaction, DRAWERS.TRANSACTION_DETAILS);
                  onSelect?.(transaction, DRAWERS.OFFSET_LOAN);
                }
                onClick?.(DRAWERS.TRANSACTION_DETAILS);
                onClick?.(DRAWERS.OFFSET_LOAN);
              }}
            >
              {dictionary?.offset_loan}
            </MenuItem>
          ) : null}
        </Menu>
      </Dropdown>
    </div>
  ) : null;
};
