import { Tooltip } from "@canopyinc/aura";
import { TransactionFieldProps } from "../Transactions/TransactionTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";

export const TransactionHint = ({ transaction }: TransactionFieldProps) => {
  return (
    <>
      {transaction?.belongs_to_loan_id != null ? (
        <div data-testid="belongs-to-loan-icon">
          <Tooltip
            content={`Loan ${transaction?.belongs_to_loan_id}`}
            // TODO onClick once Tooltip supports it
          >
            <FontAwesomeIcon icon={faInfoCircle} color="gray" size="sm" />
          </Tooltip>
        </div>
      ) : null}
    </>
  );
};
