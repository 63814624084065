import { Transaction } from "@canopyinc/api-docs/types/ts/Transaction.type";
import { Card, DataGrid, Text, formatAmountCents, formatMMDDYY } from "@canopyinc/aura";
import cn from "classnames";
import { formatInTimeZone } from "date-fns-tz";
import { useMemo } from "react";

import { TransactionHighlights } from "@/components/TransactionHighlights";
import { DATE_TIME_FORMAT_12_HOUR_WITH_TZ } from "@/constants";
import { usePluginColumns, usePlugins } from "@/hooks";
import { mdash } from "@/libs/string";
import { ImpactSummary } from "./ImpactSummary";
import { TransactionDetailsProps } from "..";

export type TransactionsImpactProps = TransactionDetailsProps & {
  title?: string;
};

type AdjustmentMap = { [key in string]?: Transaction[] };

// Sort transactions to group adjustments with their corresponding original transaction
export const sortTransactions = (transactions: Transaction[]) => {
  // Create a map to store adjustments by their corresponding original transaction ID
  const adjustmentMap: AdjustmentMap = transactions.reduce((map, txn) => {
    if (txn.adjustment_for_transaction_id) {
      return {
        ...map,
        [txn.adjustment_for_transaction_id]: [...(map[txn.adjustment_for_transaction_id] ?? []), txn],
      };
    }
    return map;
  }, {} as AdjustmentMap);

  // Build the sorted transaction list by iterating over the original transactions
  return transactions.reduce((sorted, txn) => {
    let transactions: Transaction[] = [];
    if (txn.adjustment_for_transaction_id == null) {
      // If the transaction is not an adjustment, it is included inline
      transactions = [txn];
    }
    // For each transaction, include any adjustments that are associated with it
    const transactionAdjustments = adjustmentMap[txn.transaction_id] ?? [];
    transactions = [...transactions, ...transactionAdjustments];

    return [...sorted, ...transactions];
  }, [] as Transaction[]);
};

export const TransactionsImpact = (props: TransactionsImpactProps) => {
  const rootPlugins = usePlugins();
  const plugins = rootPlugins?.components?.transaction_details?.transactions_impact;
  const columns = usePluginColumns(plugins?.fields);

  const { timeZone, title, transaction: reversal, impact, impactLoading } = props;

  const isAdjustmentByReversal = (transaction: Transaction) =>
    transaction?.adjustment_by_transaction_id === reversal?.transaction_id;

  const formattedReversalTimestamp = formatInTimeZone(
    reversal?.effective_at ?? new Date().toISOString(),
    timeZone ?? "UTC",
    DATE_TIME_FORMAT_12_HOUR_WITH_TZ
  );

  const sortedImpact = useMemo(() => sortTransactions(impact ?? []), [impact]);
  const rows =
    sortedImpact?.map((transaction) => {
      const textClassName = cn({
        "bg-canopy-colors-success-light": isAdjustmentByReversal(transaction),
      });
      return {
        ...transaction,
        issued_at: (
          <Text>
            <span className={textClassName}>
              {transaction?.issued_at != null ? formatMMDDYY(transaction.issued_at, { timeZone }) : null}
            </span>
          </Text>
        ),
        effective_at: (
          <Text>
            <span className={textClassName}>
              {transaction?.effective_at != null ? formatMMDDYY(transaction.effective_at, { timeZone }) : null}
            </span>
          </Text>
        ),
        transaction_id: (
          <Text>
            <span className={textClassName}>{transaction?.transaction_id ?? mdash}</span>
          </Text>
        ),
        transaction_summary: (
          <ImpactSummary
            isAdjustmentByReversal={isAdjustmentByReversal(transaction)}
            transaction={transaction}
            isEmphasized={[reversal?.transaction_id, reversal?.reverses_transaction_id].includes(
              transaction.transaction_id
            )}
          />
        ),
        transaction_highlights: <TransactionHighlights transaction={transaction} showHint={false} />,
        amount: (
          <Text>
            <span className={textClassName}>
              {transaction?.original_amount_cents != null
                ? formatAmountCents(transaction?.original_amount_cents)
                : mdash}
            </span>
          </Text>
        ),
      };
    }) ?? [];

  return (
    <div className="flex flex-col gap-2">
      <Text as="h3">{title ?? "Transactions Impact"}</Text>
      <Text as="p" classNames={{ text: "italic -mt-2" }}>
        At {formattedReversalTimestamp}
      </Text>
      <Card>
        <DataGrid columns={columns} loading={impactLoading} rows={rows} />
      </Card>
    </div>
  );
};
