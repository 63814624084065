import { useContext } from "react";
import AccountDrawersContext from "@/context/AccountDrawersContext";

export const useAccountDrawers = () => {
  const context = useContext(AccountDrawersContext);
  if (!context) {
    throw new Error("Must be used with AccountDrawersProvider");
  }
  return context;
};

export default useAccountDrawers;
