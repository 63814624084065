import { Tooltip } from "@canopyinc/aura";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import Link from "next/link";

interface AboutButtonProps {
  tooltipContent?: string;
  className?: string;
  href?: string;
  text?: string;
}

export const AboutButton = ({ tooltipContent, className, href, text = "About" }: AboutButtonProps) => {
  const content = (
    <div className={cn("px-3 py-1 bg-primary-50 flex items-center rounded-xl border border-primary-100", className)}>
      <FontAwesomeIcon icon={faQuestionCircle} className="leading-none text-primary-600" />
      <span className="leading-none text-sm text-primary-800 ml-1.5">{text}</span>
    </div>
  );

  const contentWithHref = href ? (
    <Link href={href} target="_blank">
      {content}
    </Link>
  ) : (
    content
  );

  return tooltipContent ? (
    <Tooltip classNames={{ panel: "px-1 mt-2", wrapper: "z-50" }} content={tooltipContent} placement="bottom-end">
      {contentWithHref}
    </Tooltip>
  ) : (
    contentWithHref
  );
};
