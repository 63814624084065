import { Transaction } from "@canopyinc/api-docs/types/ts/Transaction.type";

import { AdjustmentCell } from "@/components/Transactions/AdjustmentCell";
import { TransactionSummary } from "@/components/Transactions/TransactionSummary";

type ImpactSummaryProps = {
  isAdjustmentByReversal: boolean;
  isEmphasized?: boolean;
  transaction: Transaction;
};

export const ImpactSummary = ({ isAdjustmentByReversal, isEmphasized, transaction }: ImpactSummaryProps) => {
  if (transaction == null) {
    return null;
  }

  if (isAdjustmentByReversal) {
    return (
      <AdjustmentCell iconType={transaction.transaction_super_type === "FEE" ? undefined : "L"}>
        <TransactionSummary
          isAdjustmentByReversal={isAdjustmentByReversal}
          transaction={transaction}
          isEmphasized={isEmphasized}
        />
      </AdjustmentCell>
    );
  }

  return <TransactionSummary transaction={transaction} />;
};
