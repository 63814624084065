import { useState, useCallback } from "react";
import type { AxiosError } from "axios";
import { TApi, useApi } from "@/api/useApi";

export type TMutationOpts = {
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
  transformError?: (error: AxiosError) => any;
};

/**
 * Hook to manage your POST requests' lifecycle state.
 */
export function useMutation<Data, Args = any>(
  callback: (api: TApi, args: Args) => Promise<Data>,
  options?: TMutationOpts
) {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<Data | null>(null);

  // Mutate is the side effect that callers will use to execute their action.
  async function mutate(args: Args) {
    setError(null);
    setLoading(true);

    try {
      const res = await callback(api, args);
      setData(res);
      setLoading(false);
      options?.onSuccess?.();
      return res;
    } catch (err) {
      // @ts-expect-error
      options?.onError?.(err);
      setError("Something went wrong, please contact support@canopyservicing.com.");
      setLoading(false);
    }
  }

  const clear = useCallback(() => {
    setLoading(false);
    setError(null);
    setData(null);
  }, []);

  return { loading, error, data, mutate, clear };
}
