import React, { createContext, useEffect, useState } from "react";
import jwt from "jsonwebtoken";

import { useApi } from "@/api/useApi";
import { useI18n } from "@/hooks";
import prismatic from "@prismatic-io/embedded";
import Decimal from "decimal.js-light";
import { handleApiError } from "@/utils/error";

export type Prismatic = {
  isLoading: boolean;
};

const defaultContext: Prismatic = {
  isLoading: true,
};

export const PrismaticContext = createContext<Prismatic>(defaultContext);

export default function PrismaticProvider({ children }: { children?: React.ReactElement }) {
  const { dictionary: rootDictionary } = useI18n();
  const dictionary = rootDictionary?.canopy_connect?.tabs;

  const [isLoading, setIsLoading] = useState(defaultContext.isLoading);

  const axiosInstance = useApi();

  prismatic.init({
    prismaticUrl: "https://connect.canopyservicing.com",
    translation: {
      phrases: {
        "emptyState.integrationMarketplaceTitle": dictionary?.market_place?.embedded?.title ?? "",
        "emptyState.integrationMarketplaceText--customer": dictionary?.market_place?.embedded?.empty?.text ?? "",
        "integrations__filterBar.title": dictionary?.designer?.embedded?.title ?? "",
        "integrations__filterBar.addButton": dictionary?.designer?.embedded?.add_button ?? "",
        "emptyState.integrationsTitle": dictionary?.designer?.embedded?.empty?.title ?? "",
        "emptyState.integrationsText": dictionary?.designer?.embedded?.empty?.text ?? "",
        "emptyState.integrationsTextTwo": dictionary?.designer?.embedded?.empty?.sub_text ?? "",
        "input.filterSearchPlaceholder": { _: dictionary?.designer?.embedded?.search_placeholder ?? "" },
      },
    },
  });

  useEffect(() => {
    let refreshTokenTimeout: NodeJS.Timeout;

    const getToken = async () => {
      try {
        const request = await axiosInstance.get<{ token: string }>("/integrations/prismatic/customer/user/token");
        const token = request.data.token;

        const decodedToken = jwt.decode(token);
        if (!!decodedToken && typeof decodedToken === "object" && decodedToken.exp && decodedToken.iat) {
          const remainingMinutesUntilExp = new Decimal(decodedToken.exp).minus(decodedToken.iat);
          const timeBeforeExp = new Decimal(remainingMinutesUntilExp)
            .times(1000)
            .minus(60_000) // 1 minute before expiration
            .toNumber();

          refreshTokenTimeout = setTimeout(() => getToken(), timeBeforeExp);
        }

        if (!!token) {
          // We need a separate token for authorizing the embedded designer that is retrieved from the endpoint above.
          // This is its own signed JWT token we create UNRELATED to the `auth token` used to authorize a CanopyOS login
          await prismatic.authenticate({ token }).finally(() => {
            setIsLoading(false);
          });
        }
      } catch (e) {
        handleApiError("Prismatic.getToken", e);
      }
    };

    getToken();

    return () => {
      clearTimeout(refreshTokenTimeout);
    };
  }, []);

  return <PrismaticContext.Provider value={{ isLoading }}>{children}</PrismaticContext.Provider>;
}
