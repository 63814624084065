import React, { createContext, useReducer, useEffect } from "react";
import { restoreState } from "@/libs/storage";
import { _reducer } from "@/context/OnboardingContext/reducers";

// types
import { TOnboardStep, TOnboardState, TOnboardAction } from "@/context/OnboardingContext/onboarding-types";
import { useAuraMessages } from "@canopyinc/aura";

/**
 * Define State
 */
export const ONBOARDING_STORE_KEY = "onboarding_state";

export const stepOrder: TOnboardStep[] = [
  "ADD_APP_KEY",
  "ADD_PRODUCT",
  "ADD_ACCOUNT",
  "ADD_CUSTOMER",
  "ADD_MERCHANT",
  "ADD_FINANCE_REQUEST",
  "ADD_TEAM",
];

const initialState: TOnboardState = {
  activeStep: "ADD_APP_KEY",
  completion: {
    ADD_APP_KEY: true,
    ADD_PRODUCT: true,
    ADD_ACCOUNT: true,
    ADD_CUSTOMER: true,
    ADD_MERCHANT: true,
    ADD_FINANCE_REQUEST: true,
    ADD_TEAM: true,
  },
  completed: true,
};

/**
 * Create Context -> Provider / Consumer
 */
const context = createContext<{
  state: TOnboardState;
  dispatch: (value: TOnboardAction) => void;
}>({
  state: initialState,
  dispatch: () => {},
});
const { Provider } = context;

export function OnboardingProvider({ children }: { children: React.ReactElement }) {
  const { addMessage } = useAuraMessages();
  const [state, dispatch] = useReducer<React.Reducer<TOnboardState, TOnboardAction>>(
    // @ts-expect-error Doesn't like "| undefined" return signature
    _reducer(addMessage),
    initialState
  );

  /** Restores state from local store
   * @note To ensure this is only ever called once,
   * useEffects dependency arg should remain empty */
  useEffect(() => {
    restoreState<TOnboardState>(ONBOARDING_STORE_KEY).then((restoredState) => {
      if (!restoredState) return;
      // @ts-expect-error idk
      dispatch({ restoredState, type: "RESTORE" });
    });
  }, []);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
}

export default context;
