import { Button, Dropdown, Menu, MenuItem } from "@canopyinc/aura";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons";

import { LINE_ITEM_STATUSES, LINE_ITEM_TYPES } from "@/constants";
import { useI18n } from "@/hooks/useI18n";
import { usePlugins } from "@/hooks/usePlugins";
import { LineItem } from "@/types";
import { LineItemAction } from ".";
import { DRAWERS } from "@/context/AccountDrawersContext/types";
import { isLoan, isRestructuredLoan } from "@/utils/loan";

export const TransactionDropdown = ({
  onClick,
  onSelect,
  transaction,
}: {
  onClick?: (action: LineItemAction) => void;
  onSelect?: (transaction: LineItem, expectedDrawer: LineItemAction) => void;
  transaction?: LineItem;
}) => {
  const { dictionary: rootDictionary } = useI18n();
  const dictionary = rootDictionary?.accounts?.detail?.transaction_list?.dropdown;
  const basePlugins = usePlugins();
  const plugins = basePlugins?.accounts?.modules?.detail?.transaction_list?.dropdown;
  const type = transaction?.line_item_overview?.line_item_type;
  const status = transaction?.line_item_overview?.line_item_status;
  const isEditableCharge = type === LINE_ITEM_TYPES.CHARGE && status === LINE_ITEM_STATUSES.AUTHORIZED;
  const isEditablePayment = type === LINE_ITEM_TYPES.PAYMENT && status === LINE_ITEM_STATUSES.PENDING;
  const canChangeStatus = isEditableCharge || isEditablePayment;

  const hasReversal = transaction?.line_item_relationships?.some((relation) => relation.type === "PAYMENT_REVERSAL");
  const canReversePayment = plugins?.reverse_payment?.enabled && type === LINE_ITEM_TYPES.PAYMENT;
  const canReverseOffset = plugins?.reverse_offset?.enabled && type === LINE_ITEM_TYPES.DEBIT_OFFSET;

  return (
    <div className="flex justify-end">
      <Dropdown
        trigger={
          <Button
            testid="transaction-dropdown-trigger"
            iconOnly
            size="sm"
            variant="ghost"
            icon={<FontAwesomeIcon icon={faAngleDown} />}
          >
            Menu
          </Button>
        }
      >
        <Menu stacked>
          {plugins?.details?.enabled ? (
            <MenuItem
              testid="transaction-details-menu-item"
              key="transactionDetails"
              onClick={() => {
                const drawer = DRAWERS.TRANSACTION_DETAILS;
                transaction != null && onSelect?.(transaction, drawer);
                onClick?.(drawer);
              }}
            >
              {dictionary?.details}
            </MenuItem>
          ) : null}
          {plugins?.dispute_charge?.enabled && type === "CHARGE" ? (
            <>
              <MenuItem
                testid="dispute-charge-menu-item"
                key="disputeCharge"
                onClick={() => {
                  const drawer = DRAWERS.DISPUTE_CHARGE;
                  transaction != null && onSelect?.(transaction, drawer);
                  onClick?.(drawer);
                }}
              >
                {dictionary?.dispute_charge}
              </MenuItem>
            </>
          ) : null}
          {canReverseOffset && !hasReversal ? (
            <MenuItem
              testid="reverse-offset-menu-item"
              key="reverseOffset"
              onClick={() => {
                const drawer = DRAWERS.REVERSE_PAYMENT;
                transaction != null && onSelect?.(transaction, drawer);
                onClick?.(drawer);
              }}
            >
              {dictionary?.reverse_offset}
            </MenuItem>
          ) : null}
          {canReversePayment && !hasReversal ? (
            <MenuItem
              testid="reverse-payment-menu-item"
              key="reversePayment"
              onClick={() => {
                const drawer = DRAWERS.REVERSE_PAYMENT;
                transaction != null && onSelect?.(transaction, drawer);
                onClick?.(drawer);
              }}
            >
              {dictionary?.reverse_payment}
            </MenuItem>
          ) : null}
          {plugins?.waive_fee?.enabled && type === "FEE" ? (
            <MenuItem
              testid="waive-fee-menu-item"
              key="waiveFee"
              onClick={() => {
                const drawer = DRAWERS.WAIVE_FEE;
                transaction != null && onSelect?.(transaction, drawer);
                onClick?.(drawer);
              }}
            >
              {dictionary?.waive_fee}
            </MenuItem>
          ) : null}
          {plugins?.edit_status?.enabled && canChangeStatus ? (
            <MenuItem
              testid="edit-status-menu-item"
              key="editStatus"
              onClick={() => {
                const drawer = DRAWERS.EDIT_STATUS;
                transaction != null && onSelect?.(transaction, drawer);
                onClick?.(drawer);
              }}
            >
              {dictionary?.edit_status}
            </MenuItem>
          ) : null}
          {plugins?.offset_loan?.enabled && transaction && isLoan(transaction) && !isRestructuredLoan(transaction) ? (
            <MenuItem
              testid="offset-loan-menu-item"
              key="offsetLoan"
              onClick={() => {
                if (transaction != null) {
                  onSelect?.(transaction, DRAWERS.TRANSACTION_DETAILS);
                  onSelect?.(transaction, DRAWERS.OFFSET_LOAN);
                }
                onClick?.(DRAWERS.TRANSACTION_DETAILS);
                onClick?.(DRAWERS.OFFSET_LOAN);
              }}
            >
              {dictionary?.offset_loan}
            </MenuItem>
          ) : null}
        </Menu>
      </Dropdown>
    </div>
  );
};
