import { Card, CardBody, ProgressBar, ProgressTrack, Text, formatAmountCents } from "@canopyinc/aura";

import { TransactionDetailsProps } from ".";
import { Decimal } from "decimal.js-light";

export const RepaymentProgress = ({ transaction }: TransactionDetailsProps) => {
  if (transaction == null) {
    return null;
  }

  return (
    <div data-testid="repayment-progress" className="flex flex-col gap-2">
      <Text as="h3">Repayment Progress</Text>
      <Card>
        <CardBody>
          <div className="flex flex-col gap-2 pt-3">
            <ProgressTrack>
              <ProgressBar
                classNames={{ bar: "bg-emerald-500" }}
                testid="repayment-progress"
                percent={
                  transaction.original_amount_cents === 0
                    ? 0
                    : new Decimal(1)
                        .minus(new Decimal(transaction.balance_cents).dividedBy(transaction.original_amount_cents))
                        .times(100)
                        .toNumber()
                }
              />
            </ProgressTrack>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row gap-4">
                <Text variant="strong">
                  {formatAmountCents(
                    new Decimal(transaction.original_amount_cents).minus(transaction.balance_cents).toNumber()
                  )}
                </Text>
                <Text>Paid to Date</Text>
              </div>
              <div className="flex flex-row gap-4">
                <Text variant="strong">{formatAmountCents(transaction.original_amount_cents)}</Text>
                <Text>Original Amount</Text>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
