import React from "react";
import { Tag } from "@canopyinc/aura";
import startCase from "lodash/startCase";

// enumish keys for code-usability, statuses as they appear from the DB for values
export const VERIFICATION_STATUS = {
  ACTIVE: "active",
  SUSPENDED: "suspended",
  CLOSED: "closed",
  DEACTIVATED: "deactivated",
  PENDING: "pending",
  IN_REVIEW: "in_review",
  APPROVED: "approved",
  CONVERTED: "outlaid",
  REJECTED: "rejected",
  VOID: "void",
  INACTIVE: "inactive",
  UNVERIFIED: "unverified",
  VERIFIED: "verified",
};

// would be nice if this was exposed via the Tag component
type TagColors = "success" | "danger" | "extra" | "warning" | "info" | "default";

// formatter for turning the above enumish value into a pretty
const formatVerificationStatus = (status: string): string =>
  startCase(VERIFICATION_STATUS?.[status?.toUpperCase()]?.replace(/[_]/g, " "));

//get label colors
const getLabelColor = (status: string): TagColors => {
  switch (status?.toLowerCase()) {
    case "active":
    case "converted":
    case "verified":
      return "success";
    case "suspended":
    case "rejected":
      return "danger";
    case "closed":
    case "void":
    case "deactivated":
    case "inactive":
      return "default";
    case "pending":
    case "unverified":
      return "warning";
    case "approved":
      return "info";
    default:
      return "extra";
  }
};

export interface StatusProps {
  isSCRAActive?: boolean;
  status: string;
  subStatus?: string;
}

export const Status = ({ status, subStatus, isSCRAActive = false }: StatusProps) => {
  const selectedStatus = {
    color: getLabelColor(status),
    label: formatVerificationStatus(status),
  };

  return (
    <>
      <Tag color={selectedStatus.color} classNames={{ tag: "px-2" }}>
        {selectedStatus.label}
        {subStatus ? " • " + subStatus : ""}
      </Tag>
      {isSCRAActive ? <Tag color="extra">SCRA</Tag> : null}
    </>
  );
};

export const getStatus = ({ status, subStatus, isSCRAActive = false }: StatusProps) => {
  return [
    {
      color: getLabelColor(status),
      children: `${formatVerificationStatus(status)}${subStatus ? " • " + subStatus : ""}`,
    },
    ...(isSCRAActive ? [{ color: "extra", children: "SCRA" }] : []),
  ];
};
