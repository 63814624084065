// @ts-nocheck
import { FilterChange } from "@canopyinc/aura";
import { NextRouter } from "next/router";

export function toSearchQuery(router: NextRouter, value: FilterChange) {
  const query = Object.keys(value?.filters ?? {}).reduce((acc, cur) => {
    return [...acc, `${cur}=${value.filters[cur].join(",")}`];
  }, []);

  if (value?.search?.length > 2) {
    query?.push(`search_parameters=${value.search}`);
  }

  if (query.length !== 0) {
    //reset pagination when searching to go to first page
    router?.push({ pathname: router.pathname, query: {} });
    return `&${query?.join("&")}`;
  }

  return "";
}

export const getCurrentPageNumber = (query: NextRouter["query"]): number => {
  const pageNumber = Number(Array.isArray(query.pg_i) ? query.pg_i[0] : query.pg_i);
  const validNumber = !Number.isNaN(pageNumber);

  return validNumber && pageNumber > 1 ? pageNumber : 1;
};
